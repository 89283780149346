// -------------------------------------------
// 
// sitemap
// 
// -------------------------------------------

.historywrap-container {
  background-color: #f3f5f6;
}

.common-footer {
  position: relative;
}


.btn-pageTop {
  position: fixed;
  z-index: 100000;
  right: 26px;
  bottom: 26px;
  &.setfix {
    position: absolute;
    // float: right;
    // margin-top: -60px;
    // padding-right: 26px;
    right: 26px;
    bottom: auto;
    top: 55px;
    @include media-breakpoint-down(xs) {
      // top: 680px;
      top: auto;
      bottom: 150px;
    }
    a {
      // position: relative;
      // top: 100px;
      // z-index: 10000;
      @include media-breakpoint-down(xs) {
        // top: 680px;
      }
    }
  }
  a {
    display: block;
    background-color: #ffffff;
    border-radius: 100%;
    width: 50px;
    height: 28px;
    box-shadow: 0 0 8px #bbbbbb;
    padding-top: 22px;
    &::after {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      border: 2px solid;
      margin: 0 auto;
      border-color:  transparent transparent #00479d #00479d;
      box-shadow: .5;
      transform: rotate(-228deg);

    }
  }
}


[data-page-id="history"] {
    .content-header--bgimg {
        background: url(/assets/img/history/header.png);
        background-position: center;
        background-size: cover;
        // pc only
        @include media-breakpoint-up(sm) {
            margin-top: -100px;
            padding: 125px 0 150px;
        }
        // sp only
        @include media-breakpoint-down(xs) {
            margin-top: -70px;
            padding: 90px 0 40px;
        }
    }


    
/*---------------------------------------------
	section
---------------------------------------------*/
  .section--history {
      background-color: #f3f5f6;
      margin-top: 0;
      padding-top: 100px;
    .inner-base {
      display: flex;
      @include media-breakpoint-down(xs) {
        display: block;
      }
    }
  }



  .list-historyHeader {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 65px;
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
    }
    .item {
      padding-right: 18px;
      margin-right: 18px;
      border-right: 1px solid #dddddd;
      @include media-breakpoint-down(xs) {
        margin-bottom: 20px;
      }
      &:last-child {
        border-right: 0;
      }
      a {
        color: #00479d;
        display: block;
        position: relative;
        padding-right: 20px;
        &:hover {
          opacity: .5;
        }
        &::after {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border: 2px solid;
          border-color:  transparent transparent #00479d #00479d;
          transform: rotate(-45deg);
          position: absolute;
          right: 0;
          top: calc(50% - 6px);
        }
      }
    }
  }


/*---------------------------------------------
	col
---------------------------------------------*/
.col {
  &-history {
    width: 100%;
    position: relative;
    padding-top: 0;
    &:after {
      content: "";
      background: url("../img/history/icon_ciecle_02.png") no-repeat 0 0;
      display: block;
      width: 11px;
      height: 12px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      display: none;
      @include media-breakpoint-down(xs) {
        left: 25%;
      }
    }
    &.visibled {
      height: 100%;
      &::before {
        height: 100%;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1px;
      height: 0;
      background: #b2b2b2;
      background: -webkit-gradient(linear, left top, left bottom, from(#b2b2b2), color-stop(90%, #b2b2b2), to(#fbfbfb));
      background: -o-linear-gradient(top, #b2b2b2 0%, #b2b2b2 90%, #fbfbfb 100%);
      background: linear-gradient(180deg, #b2b2b2 0%, #b2b2b2 90%, #fbfbfb 100%);
      transition: all 3s 0.5s;
      @include media-breakpoint-down(xs) {
        left: 25%;
      }
    }
      &__item {
          margin-bottom: 40px;
          display: flex;
          position: relative;
          &.l-00 {
            .year {
                order: 1;
                text-align: right;
                margin-right: 2.5%;
                @include media-breakpoint-down(xs) {
                  order: 1;
                  margin-right: 2.5%;
                  text-align: left;
                }
                
            }
            .details {
              order: 2;
              width: 45%;
              margin-left: 5%;
              @include media-breakpoint-down(xs) {
                order: 2;
                width: 70%;
              }
              &::before {
              }
            }
        }
          &.l-01 {
              .year {
                  order: 1;
                  text-align: right;
                  margin-right: 2.5%;
                  @include media-breakpoint-down(xs) {
                    order: 1;
                    margin-right: 2.5%;
                    text-align: left;
                  }
                  
              }
              .details {
                order: 2;
                width: 45%;
                margin-left: 5%;
                @include media-breakpoint-down(xs) {
                  order: 2;
                  width: 70%;
                }
                &::before {
                }
              }
          }
          &.l-02 {
            .year {
                order: 2;
                text-align: left;
                margin-left: 2.5%;
                @include media-breakpoint-down(xs) {
                  order: 1;
                  text-align: left;
                  margin-right: 2.5%;
                  margin-left: 0;
                }
            }
            .details {
              order: 1;
              width: 45%;
              margin-right: 5%;
              @include media-breakpoint-down(xs) {
                order: 2;
                margin-right: 0;
                margin-left: 5%;
                width: 70%;
              }
              &::before {
                background: url("../img/history/img_arrow_02.png") no-repeat 0 0;
                left: auto;
                right: -12px;
                @include media-breakpoint-down(xs) {
                  background: url("../img/history/img_arrow_01.png") no-repeat 0 0;
                  left: -10px;
                  // top: auto;
                }
              }
            }
          }
          .year {
              width: 47.5%;
              @include media-breakpoint-down(xs) {
                width: 25%;
              }
              &::before {
                content: "";
                background: url("../img/history/icon_ciecle_01.png") no-repeat 0 0;
                display: block;
                width: 21px;
                height: 21px;
                position: absolute;
                top: 9px;
                left: 50%;
                transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                @include media-breakpoint-down(xs) {
                  left: 25%;
                  top: 7px;
                }
              }
              .title {
                  margin: 0;
                  line-height: 1;
                  .t1 {
                    font-size: 48px;
                    display: block;
                    color: #00479d;
                    margin-bottom: 10px;
                    @include media-breakpoint-down(xs) {
                      font-size: 27.5px;
                    }
                  }
                  .t2 {
                    font-size: 15px;
                    display: block;
                    color: #bbbbbb;
                    @include media-breakpoint-down(xs) {
                      font-size: 11px;
                    }
                  }
              }
          }
          .details {
              width: 50%;
              background-color: #ffffff;
              padding:40px;
              display: flex;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              position: relative;
              @include media-breakpoint-down(xs) {
                width: 70%;
                display: block;
                padding: 20px;
              }
              &::before {
                content: "";
                display: block;
                background: url("../img/history/img_arrow_01.png") no-repeat 0 0;
                width: 14px;
                height: 22px;
                position: absolute;
                left: -10px;
                top: 12px;
                @include media-breakpoint-down(xs) {
                  top: 5px;
                }
              }
              &.o-01 {
                .images {
                  width: 40%;
                  @include media-breakpoint-down(xs) {
                    width: auto;
                  }
                }
                .text {
                  width: 60%;
                  @include media-breakpoint-down(xs) {
                    width: auto;
                    padding:10px 0 0 0;
                  }
                }
              }
              &.o-02 {
                  display: block;
                  .text {
                    padding:0 0 20px 0;
                  }
              }
              &.o-03 {
                .text {
                  padding: 0;
                }
              }
              .text {
                box-sizing: border-box;
                  padding: 0 20px;
                  @include media-breakpoint-down(xs) {
                    padding:0 0 10px 0;
                  }
              }
              .images {
                  margin:0;
                  img {
                    width: 100%;
                  }
              }
          }
      }
  }
}




}