// -------------------------------------------
// 
// hero
// 
// -------------------------------------------
.hero {
  .inner-base {
  }
  video {
    margin: 0 auto;
    width: 100%;
    height: auto;
    vertical-align: bottom;
  }
}