

// -------------------------------------------
//
// content-header
//
// -------------------------------------------

// .content-header {
// 	padding-top: 100px;
// 	@include media-breakpoint-down(xs) {
// 		padding-top: 70px;
// 	}
// }


// -------------------------------------------
//
// 枠とかカラムとか
//
// -------------------------------------------
.inner-base {
	position: relative;
	margin-right: auto;
	margin-left: auto;
	@include media-breakpoint-up(sm) {
		max-width: calc(50% + 510px);
		padding: 0 4.2vw;
		&__w980{
			max-width: 980px;
		}
	}
	@include media-breakpoint-down(xs) {
		padding-left: 20px;
		padding-right: 20px;
		&.sp-full {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&__cw{
		width: 100%;
	}
}

p {
	margin-top: 0;
	margin-bottom: 0;
}
dl, ul, ol {
	list-style: none;
	margin: 0;
	padding: 0;
}
dd {
	margin-left: 0;
}
.section {
	@include media-breakpoint-up(sm) {
		margin-top: 50px;
	}
	@include media-breakpoint-down(xs) {
		margin-top: 40px;
	}
	&.section-freecontent {
		padding: 70px 0;
		background-color: #fff;
		@include media-breakpoint-up(sm) {
			// padding: 40px 0;
			margin-top: 0;
		}
		@include media-breakpoint-down(xs) {
			margin-top: 0;
			padding: 40px 0;
		}
	}
}

.sectionNeg100 {
	@include media-breakpoint-up(sm) {
		margin-top: -107px;
	}
	// sp only
	@include media-breakpoint-down(xs) {
		margin-top: -20px;
	}
	& > .section {
		&:nth-child(1) {
			@include media-breakpoint-down(xs) {
				margin-top: 0;
			}
		}
	}
}



// -------------------------------------------
// 並び
// -------------------------------------------
.row {
	// pc only
	@include media-breakpoint-up(sm) {
		display: flex;
		justify-content: space-between;
		&.wrap{
			flex-wrap: wrap;
		}
		&.nowrap{
			flex-wrap: nowrap;
		}
		&.flex-start{
			justify-content: flex-start;
		}
		&._2col {
			&:after{
				@include media-breakpoint-up(sm) {
					content: "";
					width: 45%;
					order: 1;
				}
			}
		}
		&._3col {
			&:after{
				@include media-breakpoint-up(sm) {
					content: "";
					width: 30%;
					order: 1;
				}
			}
		}
		&._4col {
			&:before, &:after{
				@include media-breakpoint-up(sm) {
					content: "";
					width: 22.5%;
				}
			}
			&:before{
				@include media-breakpoint-up(sm) {
					order: 1;
				}
			}
		}
		&__2col {
			width: 50%;
			&__pdr{
				width: 48%;
				padding-right: 2%;
			}
		}
		&__2colm {
			width: 47.5%;
			margin-right: 5%;
			margin-bottom: 50px;
			&__pdr{
				width: 48%;
				padding-right: 2%;
			}
			&:nth-child(even) {
				margin-right: 0;
			}
		}
		&__3col {
			width: 30%;
			margin-bottom: 50px;
			&.marginobj {
				margin-right: 5%;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
		&__3col-sp-2col{
			width: 30%;
			margin-bottom: 50px;
		}
		&__4col {
			width: 22.5%;
		}
		&__30 {
			width: 30%;
		}
		&__40 {
			width: 40%;
		}
		&__57 {
			width: 57%;
		}
		&__67 {
			width: 67%;
		}
		&.chev > [class*="col"]:not(:first-of-type) {
			position: relative;
			&::after {
				position: absolute;
				content: "";
				display: block;
				top: 50%;
				left: -46px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 19px 0 19px 26px;
				border-color: transparent transparent transparent #e8e8e8;
			}
		}
		&.chev > .row__2col {
			width: calc(50% - 35px);
		}
		&.ai--c {
			align-items: center;
		}
	}
	// sp only
	@include media-breakpoint-down(xs) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		&.sp-block{
			display: block;
		}
		&.wrap{
			flex-wrap: wrap;
		}
		&__2col {
			width: 100%;
			&:not(:first-of-type) {
				margin-top: 30px;
			}
		}
		&__2colm {
			width: 100%;
			&:not(:first-of-type) {
				margin-top: 30px;
			}
		}
		&__3col {
			width: 100%;
			margin-bottom: 40px;
		}
		&__3col-sp-2col{
			width: 47%;
			margin-bottom: 20px;
		}
		&__4col {
			width: 100%;
			margin-bottom: 20px;
		}
		&__30 {
			width: 100%;
		}
		&__40 {
			width: 100%;
		}
		&__57 {
			width: 100%;
		}
		&__67 {
			width: 100%;
		}
		&.chev > [class*="col"]:not(:first-of-type) {
			position: relative;
			margin-top: 80px;
			&::after {
				position: absolute;
				content: "";
				display: block;
				top: -40px;
    			left: 50%;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 20px 15px 0 15px;
				border-color: #e8e8e8 transparent transparent transparent;
				transform: translateX(-50%);
			}
		}
		&.reverse {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}
// -------------------------------------------
// 並び画像固定
// -------------------------------------------
.row--sprit{
	@include media-breakpoint-up(sm) {
		// .sprit__cont{
		// 	display: flex;
		// 	align-items: flex-start;
		// 	&__2col{
		// 		padding: 0 6% 80px;
		// 		width: 64%;
		// 		box-sizing: border-box;
		// 		background: #fff;
		// 		.item{
		// 			margin-top: 80px;
		// 		}
		// 	}
		// 	&__2col--fixed{
		// 		width: 36%;
		// 		&.sticky {
		// 			position: sticky;
		// 			top: 100px;
		// 			@include media-breakpoint-down(lg) {
		// 				top: 70px;
		// 			}
		// 		}
		// 		.img-wrap{
		// 			width: 100%;
		// 			height: calc(100vh - 100px);
		// 			position: relative;
		// 			@include media-breakpoint-down(lg) {
		// 				height: calc(100vh - 70px);
		// 			}
		// 		}
		// 		.fixed-img{
		// 			width: 100%;
		// 			height: calc(100vh - 100px);
		// 			background-position: center;
		// 			background-size: cover;
		// 			position: absolute;
		// 			top: 0;
		// 			opacity: 0;
    // 				transition: opacity linear .2s;
		// 			@include media-breakpoint-down(lg) {
		// 				height: calc(100vh - 70px);
		// 			}
		// 			&.is-current{
		// 				opacity: 1;
    // 					transition: opacity .2s linear;
		// 			}
		// 		}
		// 	}
		// }

		.sprit__cont{
			display: block;
			position: relative;
			align-items: flex-start;
			&__2col{
				padding: 0 6% 80px;
				width: 64%;
				box-sizing: border-box;
				background: #fff;
				margin: 0 0 0 auto;
				overflow: hidden;
				.item{
					margin-top: 80px;
				}
			}
			&__2col--fixed{
				width: 36%;
				&.sticky {
					position: absolute;
					top: 0;
					@include media-breakpoint-down(lg) {
						top: 70px;
					}
					@include media-breakpoint-down(xs) {
				    display: none;
					}
				}
				.img-wrap{
					width: 100%;
					height: calc(100vh - 100px);
					position: relative;
					@include media-breakpoint-down(lg) {
						height: calc(100vh - 70px);
					}
				}
				.fixed-img{
					width: 100%;
					height: calc(100vh - 100px);
					background-position: center;
					background-size: cover;
					position: absolute;
					top: 0;
					opacity: 0;
					z-index: 1;
					transition: opacity 0s linear .2s, z-index .2s linear;
					@include media-breakpoint-down(lg) {
						height: calc(100vh - 70px);
					}
					&.is-current{
						opacity: 1;
						z-index: 100000;
						transition: opacity .2s linear, z-index 0s linear;
					}
				}
			}
		}

	}
}
.flexbox{
	display: flex;
	align-items: center;
	&--type1{
		display: flex;
		align-items: center;
		margin:0 0 20px 0;
		// sp only
		@include media-breakpoint-down(xs) {
			margin:0 0 20px 0;
		}
	}
}

.box{
	//白背景ボックス
	&--type1{
		background: #fff;
		// pc only
		@include media-breakpoint-up(sm) {
			padding: 60px;
		}
		// sp only
		@include media-breakpoint-down(xs) {
			padding: 35px 20px;
		}
	}
	//グレー背景ボックス
	&--type2{
		background: #f3f5f6;
		box-sizing: border-box;
		// pc only
		@include media-breakpoint-up(sm) {
			padding: 30px 20px;
		}
		// sp only
		@include media-breakpoint-down(xs) {
			padding: 20px 15px;
		}
	}
}

	// pc only
	@include media-breakpoint-up(sm) {
	}
	// sp only
	@include media-breakpoint-down(xs) {
	}


.col {
	&-base {
		&__010 {
			margin-bottom: 80px;
		}
		&__020 {
			background-color: #f4f5f7;
			text-align: center;
			padding: 40px;
		}
		&__030 {
			margin-bottom: 40px;
		}
	}
	&-grey {
		background-color: #f4f5f7;
		padding: 50px;
		@include media-breakpoint-down(xs) {
			padding: 20px;
		}
	}
	&-img {
		&__010 {
			display: flex;
			&.left {
				justify-content: left;
			}
			&.center {
				justify-content: center;
			}
			&.right {
				justify-content: flex-end;
			}
			&.full {
				width: 100%;
				img {
					width: 100%;
				}
			}
		}
	}
	&-img {
		&__set {
			display: flex;
			@include media-breakpoint-down(xs) {
				display: block;
			}
			&.type1 {
				.img {
					width: 38%;
					margin-right: 2%;
					@include media-breakpoint-down(xs) {
						width: auto;
						margin-right: 0;
						margin-bottom: 20px;
					}
					img {
						width: 100%;
					}
				}
				.details {
					width: 60%;
					@include media-breakpoint-down(xs) {
						width: auto;
					}
				}
			}
			&.left {
				.img {
					order: 1;
					padding-right: 40px;
					@include media-breakpoint-down(xs) {
						padding-right: 0;
					}
				}
				.details {
					order: 2;
				}
			}
			&.right {
				.img {
					order: 2;
					padding-left: 40px;
					@include media-breakpoint-down(xs) {
						padding-left: 0;
					}
				}
				.details {
					order: 1;
				}
			}
		}
	}
}

figure {
	margin: 0;
	img {
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}
	&.cover {
		img {
			width: 100%;
			@include media-breakpoint-down(xs) {
				width: 100%;
			}
		}
	}
}

figcaption {
	&.left {
		text-align: left;
	}
	&.center {
		text-align: center;
	}
	&.right {
		text-align: right;
	}
}





// -------------------------------------------
// common
// -------------------------------------------
.content-header--bgimg {
	&.tour {
		min-height: 500px;
		background: url(/assets/img/tour/bg_tour_header.jpg);
	}
	&.company {
		min-height: 500px;
		background: url(/assets/img/company/bg_company.jpg);
	}
	&.company-31 {
		min-height: 500px;
		background: url(/assets/img/company/bg_company_philosophy.jpg);
	}
	&.maintenance {
		min-height: 500px;
		background: url(/assets/img/maintenance/bg_maintenance.jpg);
	}
	&.maintenance-office {
		// min-height: 500px;
		// background: url(/assets/img/maintenance/bg_maintenance_maintenance-office.jpg);
	}
	&.maintenance-37 {
		background: url(/assets/img/maintenance/bg_maintenance_plan.jpg);
	}
	&.maintenance-34 {
		background: url(/assets/img/maintenance/bg_maintenance_predictive.jpg);
	}
	&.maintenance-35 {
		background: url(/assets/img/maintenance/bg_maintenance_discontinued.jpg);
	}


  }

  .section-commonpagetop {
	margin-top: 70px;
	@include media-breakpoint-down(xs) {
		padding-left: 20px;
		padding-right: 20px;
	}
  }


  [data-page-id="information"] {

	.row {
		justify-content: left;
		&__3col {
			width: 30%;
			margin-right: 5%;
			margin-bottom: 50px;
			@include media-breakpoint-down(xs) {
				width: 100%;
				margin-bottom: 40px;
				margin-right: 0;
			}
			&.marginobj {
				margin-right: 5%;
			}
			&:nth-child(3n) {
				margin-right: 0;
				@include media-breakpoint-down(xs) {
					margin-right: 0;
				}
			}
			
		}
		&__4col {
			width: 22.5%;
			margin-right: 3.33%;
			margin-bottom: 50px;
			@include media-breakpoint-down(xs) {
				width: 100%;
				margin-bottom: 20px;
				margin-right: 0;
			}
			&:nth-child(4n) {
				margin-right: 0;
				@include media-breakpoint-down(xs) {
					margin-right: 0;
				}
			}
		}
	}
  }