@for $i from 0 through 16 {
	.mt-#{$i * 5} {
		margin-top: $i * 5px !important;
	}
	.mr-#{$i * 5} {
		margin-right: $i * 5px !important;
	}
	.mb-#{$i * 5} {
		margin-bottom: $i * 5px !important;
	}
	.ml-#{$i * 5} {
		margin-left: $i * 5px !important;
	}
}

@include media-breakpoint-down(xs) {
	@for $i from 0 through 16 {
		.mt-#{$i * 5}-sp {
			margin-top: $i * 5px !important;
		}
		.mr-#{$i * 5}-sp {
			margin-right: $i * 5px !important;
		}
		.mb-#{$i * 5}-sp {
			margin-bottom: $i * 5px !important;
		}
		.ml-#{$i * 5}-sp {
			margin-left: $i * 5px !important;
		}
	}
}