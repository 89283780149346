// -------------------------------------------
// 
// アコーディオン
// 
// -------------------------------------------
.js-accordion {
  &__toggle {
    cursor: pointer;
  }
  &__body {
    display: none;
  }
}