.button-goup{
  display: flex;
  width: 900px;
  margin: 0 auto;
  // sp only
  @include media-breakpoint-down(xs) {
    width: 100%;
    display: block;
    a + a{
      margin-top: 20px;
    }
  }
  &__list {
    margin: 0 auto;
  }
}

.button-set {
  display: flex;
  &.left {
    justify-content: left;
    a {
      margin: 0;
    }
  }
  &.center {
    justify-content: center;
    a {
      margin: 0;
    }
  }
  &.right {
    justify-content: flex-end;
    a {
      margin: 0;
    }
  }
  &.wide {
    a {
      width: 100%;
      margin: 0;
    }
  }
}

.button-list {
}



.button {
  &--type1{
    background: #d0466a;
    display: block;
    margin: 0 auto;
    color: #fff;
    font-size: 20px;
    text-align: center;
    position: relative;
    transition: all .2s linear;
    border: none;
    cursor: pointer;
    svg{
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      transition: all .3s ease-out;
    }
    &:hover{
      background: #e76586;
      svg{
        right: 20px;
      }
    }
    // pc only
    // @media (min-width: 768px) {
    @include media-breakpoint-up(sm) {
      width: 430px;
      padding: 20px 0;
    }
    // sp only
      @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 25px 0;
    }
    &.clr-prime{
      background-color: $brand-primary;
      font-size: 14px;
      &:hover{
        background:$brand-secondary;
      }
    }
    &.perv{
      svg{
        left: 30px;
        transform: translateY(-50%)rotate(180deg);
      }
      &:hover{
        svg{
          left: 20px;
        }
      }
    }
  }
  &--type2{
    background: $brand-primary;
    display: block;
    color: #fff;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: all .2s linear;
    border: none;
    svg{
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translateY(-50%);
      transform: translate(4em,-46%);
    }
    &:hover{
      background: $brand-primary-hov;
    }
    // pc only
    // @media (min-width: 768px) {
    @include media-breakpoint-up(sm) {
      width: 100%;
      padding: 25px 0;
      font-size: 14px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      width: 100%;
      padding: 25px 0;
    }
  }
  &--type3{
    background: $brand-primary;
    display: block;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
    text-align: center;
    position: relative;
    transition: all .2s linear;
    border: none;
    cursor: pointer;
    svg{
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      transition: all .3s ease-out;
    }
    img{
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      transition: all .3s ease-out;
    }
    &:hover{
      background: $brand-primary-hov;
      svg{
        right: 20px;
      }
      img{
        right: 20px;
      }
    }
    // pc only
    // @media (min-width: 768px) {
    @include media-breakpoint-up(sm) {
      width: 430px;
      padding: 20px 0;
    }
    // sp only
      @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 25px 0;
    }
    &.left {
      margin: 0;
      justify-content: left;
    }
    &.right {
      margin: 0;
      justify-content: flex-end;
    }
    &.perv{
      svg{
        left: 30px;
        transform: translateY(-50%)rotate(180deg);
      }
      &:hover{
        svg{
          left: 20px;
        }
      }
    }
  }
  &--type4{
    background: $brand-primary;
    display: block;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
    position: relative;
    transition: all .2s linear;
    width: 100%;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
    svg{
      position: absolute;
      top: 46%;
      right: 21px;
      transform: translateY(-50%);
      transition: all .3s ease-out;
    }
    &:after{
      content: "";
      display: block;
      width: 10px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      transform: translateY(4px);
      right: 20px;
    }
    &:hover{
      background: $brand-primary-hov;
      svg{
        animation: icon-download 1.2s linear infinite normal;
      }
    }
    // pc only
    // @media (min-width: 768px) {
    @include media-breakpoint-up(sm) {
      padding: 15px 40px 15px 20px;
      font-size: 14px;
    }
    // sp only
      @include media-breakpoint-down(xs) {
        font-size: 12px;
        padding: 15px;
        svg{
          right: 16px;
        }
        &:after{
          right: 15px;
        }
    }
  }

}
.js-form-next.invalid{
  opacity: .5;
  pointer-events: none;
}

@keyframes icon-download {
  0% {
    top: 46%;
  }
  50% {
    top: 40%;
  }
100% {
    top: 46%;
  }
}
