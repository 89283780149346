


// reformat
//////////////////////////////////////////////////////////
  header {
    border: 0;
  }
  h2 {
    border-top: inherit;
    background: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-shadow: inherit;
    padding: 0;
    // margin: inherit;
    // padding: inherit;
    border: 0;
    text-shadow: inherit;
    letter-spacing: inherit;
  }

  h3 {
    background: inherit;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
    // padding: inherit;
    letter-spacing: inherit;
  }

  h4 {
    color: inherit;
    background: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    // padding: inherit;
    text-shadow: inherit;
    letter-spacing: inherit;
  }

  h5 {
    font-weight: inherit;
    color: inherit;
    line-height: inherit;
    // padding: inherit;
    background: inherit;
    border-top: inherit;
    border-bottom: inherit;
    text-shadow: inherit;
    letter-spacing: inherit;
  }

// Base
//////////////////////////////////////////////////////////

// Core
@import "./variables";
@import "./mixins/mixins";

// Normalize
// @import "./normalize";

// Core CSS
// @import "./reboot";

// Utility classes
@import "./utilities/utilities";

body {
// Libs
//////////////////////////////////////////////////////////
@import "./libs/swiper";
@import "./libs/modal-video";
@import "./libs/magnific-popup";
@import "./my_magnific-popup";

// Common Page Components
//////////////////////////////////////////////////////////
@import "./components/components";


// Base layouts
//////////////////////////////////////////////////////////
@import "./base/frame";
@import "./base/header";
@import "./base/footer";


// Common Page Modules
//////////////////////////////////////////////////////////
@import "./modules/modules";


// Page layouts
//////////////////////////////////////////////////////////
@import "./layouts/case-study";
@import "./layouts/form";
@import "./layouts/information";
@import "./layouts/download_list";
@import "./layouts/products-info";
@import "./layouts/sitemap";
@import "./layouts/history";


// Top
//////////////////////////////////////////////////////////
@import "./top/top";
}


body {
  font-family:"Noto Sans JP","Hiragino Sans", "Hiragino Kaku Gothic ProN", "Yu Gothic", "Meiryo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  a{
    text-decoration: none;
    color: #000;
  }
  margin: 0;
  // pc only
	@include media-breakpoint-up(sm) {
  }
  // sp only
	@include media-breakpoint-down(xs) {}
  .is-sp{
    display: none;
    // sp only
	  @include media-breakpoint-down(xs) {
      display: block;
    }
  }
  .is-pc{
    display: none;
    // pc only
	  @include media-breakpoint-up(sm) {
      display: block;
    }
  }
}

  a:hover img {
    animation: inherit;
  }


  // pc only
	@include media-breakpoint-up(sm) {}
  // sp only
	@include media-breakpoint-down(xs) {}

.fadein {
  opacity : 0;
  transform : translate(0, 20px);
  transition : all 1000ms;
}

.fadein.scrollin {
  opacity : 1;
  transform : translate(0, 0);
}



// 仮
// html,
// body {
//   margin: 0;
//   padding: 0;
//   width: 100%;
//   height: 100%;
//   background: #e3e3e3;
// }
