// -------------------------------------------
// 
// テーブル
// 
// -------------------------------------------
table {
  margin-bottom: 10px;
  width: 100%;
  border-collapse: collapse;
  .t2 {
    background-color: #eaeef0;
  
  }
}
th, td {
  border: 1px solid #e8e8e8;
  .b1 {
    color: #00a0e9;
    font-weight: normal;
  }
  .b2 {
    color: #eb1226;
    font-weight: normal;
  }
}
th {
  background: #f3f5f6;
  font-weight: bold;
  white-space: nowrap;
}

// pc only
@include media-breakpoint-up(sm) {
  th, td {
    line-height: 1;
    padding: 15px 20px;
  }
  th {
    @include fz_em($font-size, 17);
  }
}
// sp only
@include media-breakpoint-down(xs) {
  table {
    // min-width: 650px;
  }
  th, td {
    padding: 7px 10px;
  }
}