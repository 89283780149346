// -------------------------------------------
//
// case-study
//
// -------------------------------------------

.section-pagetop{
  margin-top: 70px;
  @include media-breakpoint-down(xs) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

[data-page-id="case-study"] {
  .content-header--bgimg {
    background: url(/assets/img/case-study/bg_case-study_header.jpg);
    background-position: center;
    background-size: cover;
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: -100px;
      padding: 125px 0 150px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: -70px;
      padding: 90px 0 40px;
    }
  }
  .tab-block{
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: -127px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: -50px;
    }
  }
  .tab-contents{
    padding: 50px 4.2vw;
    // sp only
    @include media-breakpoint-down(xs) {
      padding: 20px;
    }
    .tag--type1{
      margin: -10px;
      a{
        margin: 10px;
        // sp only
        @include media-breakpoint-down(xs) {
          margin: 7px;
        }
      }
    }
  }
  .image-wrapper + .flexbox--type1{
    margin-top: 20px;
  }
  .is-hidden {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0 10px;
    padding:0;
  }
}

[data-page-id="case-study-detail"] {
  .content-header{
    margin-top: 25px;
    .row{
      // sp only
      @include media-breakpoint-down(xs) {
        margin-top: 0;
      }
    }
  }
  .bg--img{
    background-size: cover;
    background-position: center;

    // pc only
    @include media-breakpoint-up(sm) {
      height: 400px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      height: 320px;
    }
  }
  .box--type1{
    margin-top: -100px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 80px;
    }
  }
  .item{
    // sp only
    @include media-breakpoint-down(xs) {
      margin-bottom: 50px;
    }
    &:last-child {
      @include media-breakpoint-down(xs) {
        padding-bottom: 50px;
      }
    }
  }
  .headline--type7{
    // sp only
    @include media-breakpoint-down(xs) {
      display: block;
      color: #fff;
      font-size: 22px;
      padding: 20px;
      margin: -10px;
      &:after{
        display: none;
      }
    }
  }
  .headline--type8{
    // sp only
    @include media-breakpoint-down(xs) {
      display: block;
      color: #fff;
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
  .sprit__cont__2col{
    .bodycopy{
      // sp only
      @include media-breakpoint-down(xs) {
        background: #fff;
        padding: 40px 20px 20px;
      }
    }
  }
  .item-product{
    // sp only
    @include media-breakpoint-down(xs) {
      padding: 0 20px;
    }
  }
  .item-movie{
    // sp only
    @include media-breakpoint-down(xs) {
      padding: 0 20px;
    }
  }
  .row-product{
    // pc only
    @include media-breakpoint-up(sm) {
      margin-bottom: -40px;
    }
    .row__2col{
      // pc only
      @include media-breakpoint-up(sm) {
        width: 47%;
        margin-bottom: 40px;
      }
      // sp only
      @include media-breakpoint-down(xs) {
        &:not(:first-of-type){
          margin-top: 20px;
        }
        .image-wrapper{
          display: flex;
          justify-content: space-between;
          height: auto;
          img{
            width: 48%;
            height: auto;
            align-self: flex-start;
          }
          figcaption{
            width: 52%;
            height: auto;
          }
        }
      }

    }
  }
  .sectin-pagebpdy {
    margin-bottom: 70px;
  }
  .section-pagetop{
    margin-top: 70px;
  }
  .section-related{
    // margin-top: 70px;
    .row__3col{
      // sp only
      @include media-breakpoint-down(xs) {
        width: 47%;
      }
    }
  }
  .section-pagetop{
    margin-top: 70px;
  }
}

