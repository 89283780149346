// -------------------------------------------
//
// information
//
// -------------------------------------------

[data-page-id="information"] {
  .content-header{
    margin-top: 25px;
  }
  .content-header--bgimg {
    background: url(/assets/img/information/bg_information_header.jpg);
    background-position: center;
    background-size: cover;
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: -100px;
      padding: 125px 0 150px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: -70px;
      padding: 90px 0 40px;
    }
  }
  .tab-block{
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: -127px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: -50px;
    }
  }
  .tab-contents{
    padding: 50px 4.2vw;
    // sp only
    // margin-top: -2px;
    @include media-breakpoint-down(xs) {
      padding: 20px;
    }
    .tag--type1{
      margin: -10px;
      a{
        margin: 10px;
        // sp only
        @include media-breakpoint-down(xs) {
          margin: 7px;
        }
      }
    }
  }
  .section-tab + .section{
    @include media-breakpoint-down(xs) {
      margin-top: 0;
    }
  }
  .content-header + .section{
    @include media-breakpoint-down(xs) {
      margin-top: 20px;
    }
  }
  .content-header--bgimg + .section{
    @include media-breakpoint-down(xs) {
      margin-top: 40px;
    }
  }
  .list-info{
    padding: 30px 0 70px;
    // sp only
    @include media-breakpoint-down(xs) {
      padding: 0 0 40px;
    }
    li{
      a{
        display: flex;
        align-items: center;
        padding: 30px 0;
        border-bottom: 1px solid #dddddd;
        position: relative;
        transition: all .2s linear;
        // sp only
        @include media-breakpoint-down(xs) {
          display: block;
        }
        .tag--type3{
          margin: 0 10px 0 20px;
        }
        .flexbox{
          // sp only
          @include media-breakpoint-down(xs) {
            margin-bottom: 10px;
          }
        }
        svg{
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          transition: all .2s linear;
          // sp only
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
        &:hover{
          border-bottom: 1px solid $brand-secondary;
          .headline--type13{
            color: $brand-secondary;
          }
          svg{
            fill: $brand-secondary;
          }
        }
      }
    }
  }
  .inner-base{
    .headline--type2{
      margin: 15px 0;
      @include media-breakpoint-down(xs) {
        margin: 7px 0;
      }
    }
    .headline--type14{
      margin-bottom: 40px;
      @include media-breakpoint-down(xs) {
        margin-bottom: 30px;
      }
    }
    .row{
      margin-bottom: 50px;
    }
    .row__2col {
      width: 47%;
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
    .bodycopy{
      margin-bottom: 40px;
      @include media-breakpoint-down(xs) {
        margin-bottom: 30px;
      }
      &.note{
        color: #555555;
      }
    }
    .inline-link--arrow + .inline-link--arrow{
      margin-top: 0;
    }
  }
  .is-hidden {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0 10px;
    padding:0;
  }
}
