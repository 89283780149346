/*---------------------------------------------
   PC/SP Common
  ---------------------------------------------*/

.common-header {
  background: #fff;
  position: fixed;
  z-index: 100200;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  box-sizing: border-box;
  transition: background .1s linear, box-shadow .1s linear;

  .logo {
    margin: 0;
    line-height: 1;
    padding: 26px 3.5vw 0 2.5vw;
  }

  .logo a {
    position: relative;
    display: block;
    width: 113px;
  }

  .logo img {
    width: 100%;
    height: auto;
    transition: opacity .1s linear;
  }

  .logo .logo-white {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &--transparent {
    background: none;
  }

  &--transparent .logo .logo-white {
    opacity: 1;
  }

  &--transparent .logo .logo-black {
    opacity: 0;
  }

  &.is-scrolling,
  &:not(.sp):hover {
    background: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    transition: background .1s linear, box-shadow .1s linear;
  }

  &.is-scrolling .logo .logo-white,
  &:not(.sp):hover .logo .logo-white {
    opacity: 0;
  }

  &.is-scrolling .logo .logo-black,
  &:not(.sp):hover .logo .logo-black {
    opacity: 1;
  }

  > .inner-base {
    display: flex;
    max-width: inherit;
    padding: 0;
    align-items: flex-start;
    width: 100%;
  }

  .gn {
    line-height: 1;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .gn-set li {
    position: relative;
  }

  a:hover {
    text-decoration: none !important;
  }

  .gn__2st {
    margin-left: auto;
    display: flex;
  }

  .gn__2st > li > a {
    position: relative;
    display: block;
    width: 55px;
    height: 40px;
    padding-top: 60px;
    font-size: 12px;
    text-align: center;
    transition: color .1s linear;
  }

  &:not(.sp) .gn__2st > li > a:hover {
    color: #00479d;
  }

  .gn__2st > li > a:before,
  .gn__2st > li > a:after,
  .gn__2st > li > a span:before {
    content: "";
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 23px;
    height: 23px;
    top: 30px;
    left: calc(50% - 11.5px);
    transition: opacity .1s linear;
  }
  .gn__2st > li > a:after,
  .gn__2st > li > a span:before {
    opacity: 0;
  }

  .gn__2st > li.global > a:before {
    background-image: url(/assets/img/common/icon_global.png);
  }
  .gn__2st > li.global > a:after {
    background-image: url(/assets/img/common/icon_global_w.png);
  }
  .gn__2st > li.global > a span:before {
    background-image: url(/assets/img/common/icon_global_b.png);
  }

  .gn__2st > li.search:after {
    content: "";
    position: absolute;
    display: none;
    width: 0;
    height: 0;
    left: calc(50% - 8px);
    bottom: 0;
    border-top: solid 10px transparent;
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
    border-bottom: solid 10px #f3f5f6;
  }
  &:not(.sp) .gn__2st > li.search:hover:after {
    display: block;
  }

  .gn__2st > li.search > a:before {
    background-image: url(/assets/img/common/icon_search.png);
  }
  .gn__2st > li.search > a:after {
    background-image: url(/assets/img/common/icon_search_w.png);
  }
  .gn__2st > li.search > a span:before {
    background-image: url(/assets/img/common/icon_search_b.png);
  }

  .gn__2st > li.contact > a {
    width: 100px;
    margin-left: 20px;
    color: #fff;
    background-color: #00479d;
    transition: color .2s linear, background-color .2s linear;
  }

  &:not(.sp) .gn__2st > li.contact > a:hover {
    background-color: #2b6ebf;
  }
  .gn__2st > li.contact > a:before {
    background-image: url(/assets/img/common/icon_contact.png);
  }
  .gn__2st > li.contact > a:after,
  .gn__2st > li.contact > a span:before {
    display: none;
  }

  &--transparent .gn__2st > li > a {
    color: #fff;
  }

  &--transparent:not(.sp) .gn__2st > li > a:hover {
    color: #fff;
  }

  &--transparent .gn__2st > li.global > a:after {
    opacity: 1;
  }
  &--transparent .gn__2st > li.global > a:before,
  &--transparent .gn__2st > li.global > a span:before {
    opacity: 0;
  }

  &--transparent .gn__2st > li.search > a:after {
    opacity: 1;
  }
  &--transparent .gn__2st > li.search > a:before,
  &--transparent .gn__2st > li.search > a span:before {
    opacity: 0;
  }

  &.is-scrolling .gn__2st > li.global > a,
  &:not(.sp):hover .gn__2st > li.global > a {
    color: #000;
  }
  &:not(.sp).is-scrolling .gn__2st > li.global:hover > a,
  &:not(.sp):hover .gn__2st > li.global:hover > a {
    color: #00479d;
  }
  &.is-scrolling .gn__2st > li.global > a:before,
  &:not(.sp):hover .gn__2st > li.global > a:before {
    opacity: 1;
  }
  &.is-scrolling .gn__2st > li.global > a:after,
  &.is-scrolling .gn__2st > li.global > a span:before,
  &:not(.sp):hover .gn__2st > li.global > a:after,
  &:not(.sp):hover .gn__2st > li.global > a span:before {
    opacity: 0;
  }
  &.is-scrolling .gn__2st > li.global:hover > a span:before,
  &:not(.sp):hover .gn__2st > li.global:hover > a span:before {
    opacity: 1;
  }
  &:not(.sp).is-scrolling .gn__2st > li.global:hover > a:before,
  &:not(.sp).is-scrolling .gn__2st > li.global:hover > a:after,
  &:not(.sp):hover .gn__2st > li.global:hover > a:before
  &:not(.sp):hover .gn__2st > li.global:hover > a:after {
    opacity: 0;
  }

  &.is-scrolling .gn__2st > li.search > a,
  &:not(.sp):hover .gn__2st > li.search > a {
    color: #000;
  }
  &:not(.sp).is-scrolling .gn__2st > li.search:hover > a,
  &:not(.sp):hover .gn__2st > li.search:hover > a {
    color: #00479d;
  }
  &.is-scrolling .gn__2st > li.search > a:before,
  &:not(.sp):hover .gn__2st > li.search > a:before {
    opacity: 1;
  }
  &.is-scrolling .gn__2st > li.search > a:after,
  &.is-scrolling .gn__2st > li.search > a span:before,
  &:not(.sp):hover .gn__2st > li.search > a:after,
  &:not(.sp):hover .gn__2st > li.search > a span:before {
    opacity: 0;
  }
  &:not(.sp).is-scrolling .gn__2st > li.search:hover > a span:before,
  &:not(.sp):hover .gn__2st > li.search:hover > a span:before {
    opacity: 1;
  }
  &:not(.sp).is-scrolling .gn__2st > li.search:hover > a:before,
  &:not(.sp).is-scrolling .gn__2st > li.search:hover > a:after,
  &:not(.sp):hover .gn__2st > li.search:hover > a:before
  &:not(.sp):hover .gn__2st > li.search:hover > a:after {
    opacity: 0;
  }

  &:not(.sp) .gn__2st > li.contact:hover > a {
    color: #fff;
  }

  .gn-set li .col-navChild .searchbox {
    width: 100%;
    text-align: center;
  }

  .gn-set li .col-navChild .searchbox .searchtxt {
    display: inline-block;
    border: 1px solid #e6ecf1;
    border-right: 0 none;
    width: 474px;
    height: 50px;
    line-height: 50px;
    padding-left: 16px;
    font-size: 16px;
    font-family: inherit;
    vertical-align: top;
    appearance: none;
  }

  .gn-set li .col-navChild .searchbox .btn {
    display: inline-block;
    border: 0 none;
    background-color: #00479d;
    color: #fff;
    width: 107px;
    height: 54px;
    line-height: 54px;
    position: relative;
    font-size: 16px;
    padding-left: 30px;
    letter-spacing: 0.3em;
    outline: none;
    cursor: pointer;
    vertical-align: top;
    transition: background-color .2s linear;
  }

  &:not(.sp) .gn-set li .col-navChild .searchbox .btn:hover {
    background-color: #2b6ebf;
  }

  .gn-set li .col-navChild .searchbox .btn:after {
    content: "";
    display: block;
    background: url(/assets/img/common/icon_search_w.png) no-repeat 0 0;
    width: 16px;
    height: 16px;
    background-size: 100%;
    position: absolute;
    top: 35%;
    left: 20px;
  }



  /*---------------------------------------------
     PC Menu
    ---------------------------------------------*/

  @include media-breakpoint-up(xl) {

    .gn .sp-scroll {
      display: flex !important;
      height: 100px !important;
      justify-content: space-between;
    }

    .gn a {
      display: block;
      position: relative;
      color: #000;
    }

    .gn__1st {
      display: flex;
      font-size: 16px;
    }

    .gn__1st > li button {
      display: none;
    }

    .gn__1st > li > a {
      display: block;
      height: 100%;
      padding: 0 1.5vw;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: .03em;
      box-sizing: border-box;
      transition: color .1s linear;
    }

    .gn__1st > li > a.is-current {
      color: #00479d;
    }

    &:not(.sp) .gn__1st > li > a:hover {
      color: #00479d;
    }

    .gn__1st > li > a span {
      position: relative;
      display: flex;
      height: 100%;
      align-items: center;
    }

    .gn__1st > li > a.is-current span::after {
      height: 5px;
      width: 100%;
      background: #00479d;
      transform: translate(-50%, 1px);
    }

    .gn__1st > li > a span::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      height: 5px;
      width: 0;
      transform: translateX(-50%);
      background: #00479d;
      transition: width .3s ease-out, background-color 0s linear .3s;
    }

    &:not(.sp) .gn__1st > li:hover > a span::after {
      width: 100%;
      transition: width .3s ease-out, background-color 0s linear 0s;
    }

    .gn__1st > li > a br {
      display: none;
    }

    &--transparent .gn__1st > li > a {
      color: #fff;
    }

    &--transparent .gn__1st > li > a.is-current {
      color: #fff;
    }

    &--transparent .gn__1st > li > a.is-current span::after {
      background: #fff;
    }

    &--transparent .gn__1st > li > a span::after {
      background: #fff;
    }

    &--transparent:not(.sp) .gn__1st > li > a:hover {
      color: #fff;
    }

    &.is-scrolling .gn__1st > li > a,
    &:not(.sp):hover .gn__1st > li > a {
      color: #000;
      transition: color .1s linear;
    }

    &.is-scrolling .gn__1st > li > a.is-current,
    &:not(.sp):hover .gn__1st > li > a.is-current {
      color: #00479d;
      transition: color .1s linear;
    }

    &.is-scrolling .gn__1st > li > a.is-current span::after,
    &:not(.sp):hover .gn__1st > li > a.is-current span::after {
      background: #00479d;
    }

    &.is-scrolling .gn__1st > li > a span::after,
    &:not(.sp):hover .gn__1st > li > a span::after {
      background: #00479d;
    }

    &:not(.sp).is-scrolling .gn__1st > li:hover > a,
    &:not(.sp):hover .gn__1st > li:hover > a {
      color: #00479d;
    }


    /* ----- Mega Menu ----- */

    .gn-set li .col-navChild {
      position: fixed;
      z-index: 1;
      display: block !important;
      width: calc(100% - 200px);
      height: 0;
      top: 100px;
      left: 100px;
      overflow: hidden;
      transition: height .2s ease-out .1s, z-index .3s linear 0s;
    }
    /*
    &:not(.sp) .gn-set li:hover .col-navChild {
      height: 280px;
    }
    */
    &.pc-menu-open .gn-set li .col-navChild {
      height: 280px;
    }
    &:not(.sp) .gn-set li:hover .col-navChild {
      z-index: 100100;
      transition: height .2s ease-out .1s, z-index 0s linear .1s;
    }

    @media screen and (max-width: 1229px), print {
      .gn-set li .col-navChild {
        width: calc(100% - 8.4vw);
        left: 4.2vw;
      }
    }

    .gn-set li .col-navChild__inner {
      width: 100%;
      height: 100%;
    }

    .gn-set li .col-navChild__inner2 {
      background-color: #ffffff;
      display: flex;
      height: 100%;
      padding: 60px 0 0;
      border-top: solid 1px #eee;
      box-sizing: content-box;
    }

    .gn-set li .col-navChild .title {
      width: 30.5%;
      padding: 0 5% 0 9.5%;
      box-sizing: border-box;
    }

    .gn-set li .col-navChild .title .ttl {
      font-size: 24px;
      line-height: 1.4;
      margin: 0;
      color: #00479d;
    }

    .gn-set li .col-navChild .title .ttl  a {
      color: #00479d;
      transition: color .2s linear;
    }
    &:not(.sp) .gn-set li .col-navChild .title .ttl  a:hover {
      color: #00a0e9;
    }

    .gn-set li .col-navChild .details {
      width: 67.5%;
      line-height: 1.6;
    }

    .gn-set li .col-navChild .details a {
      padding-right: 30px;
      line-height: 1.6;
      transition: color .2s linear;
    }
    .gn-set li .col-navChild .details .list-gn__01 a {
      padding-right: 0;
    }
    &:not(.sp) .gn-set li .col-navChild .details a:hover {
      color: #00a0e9;
    }

    .gn-set li.search .col-navChild__inner2 {
      padding-top: 105px;
      background-color: #f3f5f6;
      border-top: 0 none;
    }

    .gn-set li .col-navChild .list-gn__01 {
      display: flex;
    }

    .gn-set li .col-navChild .list-gn__01 .item-header {
      margin-right: 60px;
    }

    .gn-set li .col-navChild .list-gn__01 .item-header:last-child {
      margin-right: 0;
    }

    .gn-set li .col-navChild .list-gn__01 .item-header .img {
      margin: 0 0 15px 0;
      background-color: #212a34;
      overflow: hidden;
    }

    .gn-set li .col-navChild .list-gn__01 .item-header .img img {
      vertical-align: top;
      transition: transform .2s linear, opacity .2s linear;
    }
    &:not(.sp) .gn-set li .col-navChild .list-gn__01 .item-header a:hover .img img {
      opacity: .7;
      transform: scale(1.05);
    }

    .gn-set li .col-navChild .list-gn__01 .item-header a .btn {
      position: relative;
      margin: 10px 0 0;
    }

    .gn-set li .col-navChild .list-gn__01 .item-header a .btn:after {
      content: "";
      background: url(/assets/img/top/img_arrow_blk.png) no-repeat 0 0;
      width: 24px;
      height: 8px;
      margin-left: 10px;
      background-size: 100%;
      position: absolute;
      top: 45%;
      transition: transform .3s ease-out;
    }
    &:not(.sp) .gn-set li .col-navChild .list-gn__01 .item-header a:hover .btn:after {
      background: url(/assets/img/top/img_arrow_bl2.png) no-repeat 0 0;
      background-size: 100%;
      transform: translate3d(5px, 0, 0);
    }

    .gn-set li .col-navChild .list-gn__02 {
      display: flex;
      flex-wrap: wrap;
    }

    .gn-set li .col-navChild .list-gn__02 .item-header {
      width: 27%;
      margin-right: 5%;
      margin-bottom: 26px;
    }

    .gn-set li .col-navChild .list-gn__02 .item-header:nth-child(3n) {
      margin-right: 0;
    }

    .gn-set li .col-navChild .list-gn__02 .item-header a:after {
      content: "";
      background: url(/assets/img/top/img_arrow_blk.png) no-repeat 0 0;
      width: 24px;
      height: 8px;
      background-size: 100%;
      position: absolute;
      top: 45%;
      right: 0;
      transition: transform .3s ease-out;
    }
    &:not(.sp) .gn-set li .col-navChild .list-gn__02 .item-header a:hover:after {
      background: url(/assets/img/top/img_arrow_bl2.png) no-repeat 0 0;
      background-size: 100%;
      transform: translate3d(5px, 0, 0);
    }

    .gn-set li .col-navChild .list-gn__02 .item-header a span {
      font-size: 12px;
    }

    + .header-bg {
      position: fixed;
      z-index: 100199;
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0;
      transition: height 0s linear .4s, opacity .2s linear .1s;
    }
    &.pc-menu-open + .header-bg {
      height: 200%;
      opacity: .7;
      transition: height 0s linear .1s, opacity .2s linear .1s;
    }

    &.pc-menu-delay {
      transition: background .1s linear .3s, box-shadow .1s linear .3s;
    }

    &.pc-menu-delay .logo img {
      transition: opacity .1s linear .3s;
    }

    &.pc-menu-delay .gn__1st > li > a {
      transition: color .1s linear .3s;
    }

    &.pc-menu-delay .gn__1st > li > a.is-current {
      transition: color .1s linear .3s;
    }

    &.pc-menu-delay .gn__2st > li.global > a {
      transition: color .1s linear .3s;
    }

    &.pc-menu-delay .gn__2st > li.search > a  {
      transition: color .1s linear .3s;
    }

    &.pc-menu-delay .gn__2st > li > a:after,
    &.pc-menu-delay .gn__2st > li > a:before,
    &.pc-menu-delay .gn__2st > li > a span:before {
      transition: opacity .1s linear .3s;
    }

    #menu-toggle {
      display: none;
    }

  }

  @media screen and (max-width: 1229px) and (min-width: 977px), print {
    .gn-set li .col-navChild {
      width: calc(100% - 8.4vw);
      left: 4.2vw;
    }

    .gn-set li .col-navChild__inner2 {
      padding: 50px 0 0;
    }

    .gn__1st > li > a br {
      display: inline;
    }
  }



  /*---------------------------------------------
     SP Menu
    ---------------------------------------------*/

  @include media-breakpoint-down(lg) {
    height: 70px;
    transition: background .1s linear !important;

    .logo .logo-white {
      transition: opacity .1s linear !important;
    }

    .logo .logo-black {
      transition: opacity .1s linear !important;
    }

    &.sp-menu-delay {
      background: #fff;
    }

    &.sp-menu-delay .logo .logo-white {
      opacity: 0;
    }

    &.sp-menu-delay .logo .logo-black {
      opacity: 1;
    }

    .inner-base {
      position: relative;
      display: block;
      height: 100%;
      padding: 0;
      width: 100%;
    }

    .logo {
      width: 113px;
      max-width: 30vw;
      height: 54px;
      padding: 16px 0 0 20px;
    }

    .gn__2st {
      position: absolute;
      height: 100%;
      top: 0;
      right: 60px;
    }

    .gn__2st > li > a {
      padding-top: 0;
      width: 50px;
      max-width: 12.5vw;
      height: 70px;
    }

    .gn__2st > li.contact > a {
      width: 50px;
      height: 70px;
      margin: 0 3vw 0 0;
      background-color: transparent;
    }

    &:not(.sp) .gn__2st > li.contact > a:hover {
      background-color: transparent;
    }

    .gn__2st > li > a span {
      display: block;
      text-indent: -9999px;
      overflow: hidden;
    }

    .gn__2st > li > a:before,
    .gn__2st > li > a:after,
    .gn__2st > li > a span:before {
      top: 25px;
    }

    .gn__2st > li.contact > a:after,
    .gn__2st > li.contact > a span:before {
      display: block;
    }

    .gn__2st > li.contact > a:before {
      background-image: url(/assets/img/common/icon_contact_g.png);
    }
    .gn__2st > li.contact > a:after {
      background-image: url(/assets/img/common/icon_contact.png);
    }
    .gn__2st > li.contact > a span:before {
      background-image: url(/assets/img/common/icon_contact_b.png);
    }

    &--transparent .gn__2st > li.contact > a:after {
      opacity: 1;
    }
    &--transparent .gn__2st > li.contact > a:before,
    &--transparent .gn__2st > li.contact > a span:before {
      opacity: 0;
    }

    &.is-scrolling .gn__2st > li.contact > a,
    &:not(.sp):hover .gn__2st > li.contact > a {
      color: #000;
    }
    &:not(.sp).is-scrolling .gn__2st > li.contact:hover > a,
    &:not(.sp):hover .gn__2st > li.contact:hover > a {
      color: #00479d;
    }
    &.is-scrolling .gn__2st > li.contact > a:before,
    &:not(.sp):hover .gn__2st > li.contact > a:before {
      opacity: 1;
    }
    &.is-scrolling .gn__2st > li.contact > a:after,
    &.is-scrolling .gn__2st > li.contact > a span:before,
    &:not(.sp):hover .gn__2st > li.contact > a:after,
    &:not(.sp):hover .gn__2st > li.contact > a span:before {
      opacity: 0;
    }
    &:not(.sp).is-scrolling .gn__2st > li.contact:hover > a span:before,
    &:not(.sp):hover .gn__2st > li.contact:hover > a span:before {
      opacity: 1;
    }
    &:not(.sp).is-scrolling .gn__2st > li.contact:hover > a:before,
    &:not(.sp).is-scrolling .gn__2st > li.contact:hover > a:after,
    &:not(.sp):hover .gn__2st > li.contact:hover > a:before
    &:not(.sp):hover .gn__2st > li.contact:hover > a:after {
      opacity: 0;
    }

    .gn__2st > li > a:before,
    .gn__2st > li > a:after,
    .gn__2st > li > a span:before {
      transition: opacity .1s linear;
    }

    &.sp-menu-delay .gn__2st > li.global > a:before {
      opacity: 1;
    }
    &.sp-menu-delay .gn__2st > li.global > a:after,
    &.sp-menu-delay .gn__2st > li.global > a span:before {
      opacity: 0;
    }
    &:not(.sp).sp-menu-delay .gn__2st > li.global:hover > a span:before {
      opacity: 1;
    }
    &:not(.sp).sp-menu-delay .gn__2st > li.global:hover > a:before
    &:not(.sp).sp-menu-delay .gn__2st > li.global:hover > a:after {
      opacity: 0;
    }

    &.sp-menu-delay .gn__2st > li.search > a:before {
      opacity: 1;
    }
    &.sp-menu-delay .gn__2st > li.search > a:after,
    &.sp-menu-delay .gn__2st > li.search > a span:before {
      opacity: 0;
    }
    &:not(.sp).sp-menu-delay .gn__2st > li.search:hover > a span:before {
      opacity: 1;
    }
    &:not(.sp).sp-menu-delay .gn__2st > li.search:hover > a:before
    &:not(.sp).sp-menu-delay .gn__2st > li.search:hover > a:after {
      opacity: 0;
    }

    &.sp-menu-delay .gn__2st > li.contact > a:before {
      opacity: 1;
    }
    &.sp-menu-delay .gn__2st > li.contact > a:after,
    &.sp-menu-delay .gn__2st > li.contact > a span:before {
      opacity: 0;
    }
    &:not(.sp).sp-menu-delay .gn__2st > li.contact:hover > a span:before {
      opacity: 1;
    }
    &:not(.sp).sp-menu-delay .gn__2st > li.contact:hover > a:before
    &:not(.sp).sp-menu-delay .gn__2st > li.contact:hover > a:after {
      opacity: 0;
    }

    .gn__2st > li.search .col-navChild {
      position: fixed;
      z-index: 1;
      display: block !important;
      width: 100%;
      height: 0;
      top: 70px;
      left: 0;
      overflow: hidden;
      transition: height .2s ease-out;
    }
    .gn__2st > li.search.on .col-navChild {
      height: 200px;
      z-index: 3;
    }

    .gn-set li.search .col-navChild__inner {
      height: 100%;
      padding-top: 73px;
      background-color: #f3f5f6;
    }

    .gn-set li .col-navChild .searchbox .btn {
      width: 60px;
      padding: 0;
      letter-spacing: .2em;
    }
    .gn-set li .col-navChild .searchbox .btn:after {
      content: none;
    }

    &:not(.sp) .gn__2st > li.search:hover:after {
      display: none;
    }
    .gn__2st > li.search.on:after {
      display: block !important;
    }

    .gn__2st > li.search.on > a {
      color: #00479d;
    }

    .gn__2st > li.search.on > a span:before {
      opacity: 1;
    }
    .gn__2st > li.search.on > a:before,
    .gn__2st > li.search.on > a:after {
      opacity: 0;
    }

    .gn {
      display: block;
      line-height: 1.5;
    }

    .gn .main-menu {
      position: relative;
      z-index: 2;
      max-height: calc(100vh - 70px);
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }

    .gn .main-menu .sp-scroll {
      display: none;
    }

    .gn__1st {
      display: block;
      padding-bottom: 120px;
      position: relative;
      background-color: #00479d;
      width: 100%;
      overflow: hidden;
    }

    .gn__1st > li {
      border-bottom: 1px solid #2662ac;
      position: relative;
    }
    .gn__1st > li:first-child {
      border-top: 1px solid #2662ac;
    }

    .gn__1st li a {
      display: flex;
      min-height: 40px;
      padding: 10px 20px;
      color: #fff;
      align-items: center;
    }

    .gn__1st li a.arrow:after {
      content: "";
      margin-left: 12px;
      display: inline-block;
      background: url(/assets/img/top/img_arrow.png) no-repeat 0 0;
      width: 24px;
      height: 8px;
      background-size: 100%;
    }

    .gn__1st li a.arrow2:after {
      content: "";
      margin-left: 12px;
      display: inline-block;
      background: url(/assets/img/top/img_arrow.png) no-repeat 0 0;
      background-size: 100%;
      width: 24px;
      height: 8px;
      position: absolute;
      right: 18px;
    }

    .gn__1st li a.arrow3:after {
      content: "";
      display: inline-block;
      background: url(/assets/img/top/img_external.png) no-repeat 0 0;
      background-size: 100%;
      width: 14px;
      height: 14px;
      position: absolute;
      right: 22px;
    }

    .gn__1st li .menu-toggle {
      position: absolute;
      display: block;
      width: 60px;
      height: 60px;
      right: 0;
      top: 0;
      margin: 0;
      padding: 0;
      text-decoration: none;
      text-indent: -9999px;
      border: 0 none;
      border-left: 1px solid #2662ac;
      cursor: pointer;
      outline: none;
      overflow: hidden;
      background-color: transparent;
    }
    .gn__1st li .menu-toggle:before {
      position: absolute;
      top: 29px;
      left: 22px;
      content: '';
      display: inline-block;
      width: 15px;
      height: 1px;
      background-color: #fff;
    }
    .gn__1st li .menu-toggle:after {
      position: absolute;
      content: '';
      display: inline-block;
      width: 1px;
      height: 15px;
      background-color: #fff;
      top: 22px;
      left: 29px;
      transition: transform .2s ease-in-out;
    }
    .gn__1st li .menu-toggle.on:after {
      transform: rotate(90deg);
    }
    .gn__1st li .menu-toggle.on {
      background-color: #004ead;
    }

    .gn__1st li a br {display: none;}

    .gn__1st li .col-navChild {
      display: none;
      font-size: 14px;
      background-color: #004ead;
    }

    .gn__1st li .col-navChild__inner {
      padding: 10px 0;
    }

    .gn__1st li .col-navChild .title {
      display: none;
    }

    .gn__1st li .col-navChild .list-nav .item-header a {
      display: flex;
      align-items: center;
    }

    .gn__1st li .col-navChild .list-nav .item-header a .img {
      margin: 0 15px 0 0;
    }

    .gn__1st li .col-navChild .list-nav .item-header a .img img {
      width: auto;
      height: 58px;
      vertical-align: top;
    }

    .gn__1st li .col-navChild .list-nav .item-header a.arrow:after {
      content: "";
      margin-left: 12px;
      display: inline-block;
      background: url(/assets/img/top/img_arrow.png) no-repeat 0 0;
      width: 24px;
      height: 8px;
      background-size: 100%;
    }

    .gn__1st li .col-navChild .list-gn__02 .item-header .btn a {
      display: block;
      min-height: 0;
    }

    .gn__1st li .col-navChild .list-gn__02 .item-header .btn a:after {
      content: "";
      margin-left: 12px;
      display: inline-block;
      background: url(/assets/img/top/img_arrow.png) no-repeat 0 0;
      width: 24px;
      height: 8px;
      background-size: 100%;
    }

    + .header-bg {
      position: fixed;
      z-index: 100199;
      width: 100%;
      height: 0;
      top: 0;
      left: 0;
      background-color: #000;
      opacity: 0;
      transition: height 0s linear .3s, opacity .2s linear;
    }
    &.sp-menu-open + .header-bg {
      height: 200%;
      opacity: .7;
      transition: height 0s linear, opacity .2s linear;
    }

  }

  @include media-breakpoint-down(xs) {

    .gn-set li .col-navChild .searchbox .searchtxt {
      width: 200px;
    }

  }

}
