// -------------------------------------------
//
// インラインのテキスト全般
//
// -------------------------------------------
[class^="headline"] {
  font-weight: bold;
  line-height: 1.2;
  transition: color .2s linear;
  & > img {
    width: auto;
    max-width: 100%;
  }
  & > .sub {
    font-size: .8em;
    margin-left: 1em;
  }
}
.headline {
  // メインの見出し白
  &--type1 {
    font-weight: bold;
    color: #fff;
    @include media-breakpoint-up(sm) {
      font-size: 34px;
      margin: 70px 0 0 0;
    }
    @include media-breakpoint-down(xs) {
      font-size: 24px;
      margin: 40px 0 0 0;
    }
    .small{
      font-weight: normal;
      line-height: 1.6;
      @include media-breakpoint-up(sm) {
        font-size: 20px;
        display: block;
        margin-top: 20px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 13px;
      }
    }
  }
  // メインの見出し黒
  &--type2 {
    font-weight: bold;
    line-height: 1.5;
    @include media-breakpoint-up(sm) {
      font-size: 34px;
      margin: 25px 0;
    }
    @include media-breakpoint-down(xs) {
      font-size: 24px;
      margin: 0;
    }
  }
  &--type3 {
    font-weight: normal;
    color: #333333;
    @include media-breakpoint-up(sm) {
      font-size: 14px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 13px;
    }
  }
  &--type4 {
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 17px;
    }
  }
  // 見出しメインカラー
  &--type5 {
    font-weight: bold;
    color: $brand-primary;
    @include media-breakpoint-up(sm) {
      font-size: 30px;
      margin: 0 0 25px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 23px;
      margin: 0 0 20px;
    }
  }
  // 見出しサブカラー
  &--type6 {
    font-weight: bold;
    color: $brand-secondary;
    @include media-breakpoint-up(sm) {
      font-size: 30px;
      margin: 0 0 25px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 23px;
      margin: 0 0 20px;
    }
  }
  // 見出しアンダーライン
  &--type7 {
    font-weight: bold;
    line-height: 1.5;
    @include media-breakpoint-up(sm) {
      font-size: 30px;
      margin: 15px 0 25px 0;
      &:after{
        content:"";
        display:block;
        width:40px;
        height:1px;
        background-color: $brand-secondary;
        margin-top: 10px;
      }
    }
    @include media-breakpoint-down(xs) {
      font-size: 22px;
      margin: 15px 0 25px 0;
      &:after{
        content:"";
        display:block;
        width:40px;
        height:1px;
        background-color: $brand-secondary;
        margin-top: 15px;
      }
    }
  }
  // 小見出しサブカラー
  &--type8 {
    font-weight: normal;
    color: $brand-secondary;
    @include media-breakpoint-up(sm) {
      font-size: 14px;
    }
    @include media-breakpoint-down(xs) {
    }
  }
  // 中見出し黒
  &--type9 {
    font-weight: bold;
    @include media-breakpoint-up(sm) {
      font-size: 24px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
    &.t1 {
      @include media-breakpoint-up(sm) {
        font-size: 22px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 18px;
      }
    }
    &.t2 {
      @include media-breakpoint-up(sm) {
        font-size: 20px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }
    }
    &.t3 {
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }
  }
  // 中見出し
  &--type10 {
    font-weight: bold;
    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 15px;
    }
  }
  // 中見出し
  &--type11 {
    font-weight: bold;
    line-height: 1.4;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 14px;
    }
  }
  // 日付
  &--type12 {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
  }
  // ニュース一覧等
  &--type13 {
    font-weight: normal;
    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 15px;
    }
  }
  // 日付大
  &--type14 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #b1b1b1;
    @include media-breakpoint-up(sm) {
    }
    @include media-breakpoint-down(xs) {
    }
  }
  // 中見出し
  &--type15 {
    font-weight: bold;
    line-height: 1.4;
    @include media-breakpoint-up(sm) {
      font-size: 28px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 17px;
    }
    .small{
      display: block;
      line-height: 1.4;
      margin-top: 5px;
      @include media-breakpoint-up(sm) {
        font-size: 20px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 12px;
      }
    }
  }
  // 製品技術見出し
  &--type16 {
    font-weight: bold;
    line-height: 1.5;
    @include media-breakpoint-up(sm) {
      font-size: 30px;
      margin: 10px 0 25px 0;
    }
    @include media-breakpoint-down(xs) {
      font-size: 22px;
      margin: 15px 0 25px 0;
    }
  }
  // sitemap
  &--type17 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #dddddd;
    position: relative;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 16px;
      margin-bottom: 30px;
    }
    a {
      color: #333333;
      display: block;
      transition: color .5s;
      &:after {
        content: "";
        background: url(/assets/img/top/img_arrow_bl.png) no-repeat 0 0;
        width: 24px;
        height: 8px;
        background-size: 100%;
        display: inline-block;
        position: absolute;
        top: 6px;
        right: 0;
        transition: right .5s;
      }
      &:hover {
        color: #00a0e9;
        &:after {
          right: -10px;
        }
      }
    }
  }
}
.bodycopy{
  transition: color .2s linear;
  // pc only
	@include media-breakpoint-up(sm) {
    font-size: 16px;
    line-height: 2;
  }
  // sp only
	@include media-breakpoint-down(xs) {
    font-size: 15px;
    line-height: 1.8;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.txt18 {
    font-size: 18px;
  }
  &.txt17 {
    font-size: 17px;
  }
  &.txt16 {
    font-size: 16px;
  }
  &.txt15 {
    font-size: 15px;
  }
  &.txt14 {
    font-size: 14px;
  }

  &.note {
    font-size: 14px;
    color: #777777;
    position: relative;
    padding-left: 1.5em;
    &::before {
      content: "※";
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .a1 {
    color: #00a0e9;
    &:hover {
      text-decoration: underline;
    }
  }

  .b1 {
    font-weight: bold;
  }

  .b2 {
    color: #eb1226;
    font-weight: normal;
  }

}
a{
  &.inline-link{
    text-decoration: none;
    color: $brand-secondary;
    transition: color 0.2s linear ;
    &:hover{
      color: $brand-secondary-hov;
    }
    &--arrow{
      display: block;
      margin-top: 30px;
      text-decoration: none;
      color: $brand-secondary;
      transition: color 0.2s linear ;
      svg{
        fill: $brand-secondary;
        transform: translate(10px,-50%);
        transition: all .3s ease-out;
      }
      &:hover{
        color: $brand-secondary-hov;
        svg{
          fill: $brand-secondary-hov;
          transform: translate(20px,-50%);
        }
      }
      img{
        transition: all .3s ease-out;
        margin-left: 10px;
      }
      &:hover{
        img{
          transform: translate(10px,0);
        }
      }
    }
  }
  &:hover{
    .headline{
      &--type4 {
        color: #555;
      }
    }
    .bodycopy{
      color: #555;
    }
  }
}


.hr-base__010 {
  display: block;
  border: 0;
  // border-bottom: 1px #f1f1f1 solid;
  width: 100%;
  clear: both;
  background-color: #f1f1f1;
  height: 1px;
  line-height: 1px;
  padding: 0;
  // padding-top: 60px;
  margin: 0;
  margin-bottom: 60px;
}


.content-header--bgimg {
  &.bg-comon {
    margin-top: -70px;
    padding: 90px 0 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
}








.article-entrybody {

  a {
    color: #00a0e9;
    &:hover {
      text-decoration: underline;
    }
  }


  h1 {
    font-weight: bold;
    color: $brand-primary;
    @include media-breakpoint-up(sm) {
      font-size: 30px;
      margin: 0 0 25px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 23px;
      margin: 0 0 20px;
    }
  }

  h2 {
    font-weight: bold;
    line-height: 1.5;
    @include media-breakpoint-up(sm) {
      font-size: 30px;
      margin: 0 0 25px;
      &:after{
        content:"";
        display:block;
        width:40px;
        height:1px;
        background-color: $brand-secondary;
        margin-top: 10px;
      }
    }
    @include media-breakpoint-down(xs) {
      font-size: 22px;
      margin: 0 0 25px;
      &:after{
        content:"";
        display:block;
        width:40px;
        height:1px;
        background-color: $brand-secondary;
        margin-top: 15px;
      }
    }
  }


  h3{
    font-weight: bold;
    margin: 0 0 25px;
    @include media-breakpoint-up(sm) {
      font-size: 24px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }

  h4 {
    margin: 0 0 25px;
      @include media-breakpoint-up(sm) {
        font-size: 22px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 18px;
      }
  }

  h5 {
    margin: 0 0 25px;
      @include media-breakpoint-up(sm) {
        font-size: 20px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }
  }
  
  h6 {
    margin: 0 0 25px;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
  }

  p {
    margin: 0 0 2em;
  }

  img {
    height: auto!important;
    @include media-breakpoint-down(xs) {
      width: 100%!important;
    }
  }

  ul {
    font-size: 16px;
    line-height: 1.6;
    margin: 0 0 25px;
    li {
      position: relative;
      padding-left: 1em;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "・";
        display: inline-block;
      }
    }
  }


  ol {
    font-size: 16px;
    line-height: 1.6;
    counter-reset: number;
    margin: 0 0 25px;
    li {
      position: relative;
      padding-left: 1.5em;
      &::before {
        counter-increment: number;
        content: counter(number) ".";
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
        width: 22px;
        height: 20px;
        border-radius: 12px;
        line-height: 1;
        padding-top: 2px;
      }
    }
  }

}


.article-entryFooter {
  clear: both;
}