// -------------------------------------------
// 
// ページ内アンカー
// 
// -------------------------------------------
.anchor-links {
  display: flex;
  justify-content: center;
  a {
    display: inline-block;
    position: relative;
    color: #000;
    padding-right: 56px;
    border-right: 1px solid #000;
    margin: 0 15px;
    &::after {
      position: absolute;
      content: "";
      display: block;
      top: 50%;
      right: 30px;
      width: 10px;
      height: 10px;
      transform: translate(-50%, -80%) rotate(45deg);
      border: 1px solid #000;
      border-top: 0;
      border-left: 0;
    }
    &:hover {
      text-decoration: none !important;
    }
  }
  // pc only
  @include media-breakpoint-up(sm) {
    margin-bottom: 70px;
    a {
      &::after {
      }
      &:hover {
        &::after {
          animation: anchor1 .5s ease forwards;
        }
      }
    }
  }
  // sp only
  @include media-breakpoint-down(xs) {
    border-radius: 20px;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 30px;
    li {
      padding: 10px 0;
      text-align: center;
    }
    a {
      padding-right: 16px;
      border-right: 1px solid #000;
      margin: 0 5px;
      font-size: 10px;
      &::after {
        width: 5px;
        height: 5px;
        right: 3px;
      }
    }

  }
}
.anc-target {
  // pc only
  @include media-breakpoint-up(sm) {
    padding-top: 80px;
    margin-top: 0;
  }
  // sp only
  @include media-breakpoint-down(xs) {
  }

}
@keyframes anchor1 {
  0% {
    transform: translate(-50%, -80%) rotate(45deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  100% {
    transform: translate(-50%, -80%) rotate(45deg);
  }
}