// -------------------------------------------
//
// products-info
//
// -------------------------------------------

[data-page-id="products-info"] {
  .content-header--bgimg {
    background: url(/assets/img/products-info/bg_products-info_header.jpg);
    background-position: center;
    background-size: cover;
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: -100px;
      padding: 125px 0 120px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: -70px;
      padding: 90px 0 40px;
    }
  }
  .image-wrapper{
    .text-in-img{
    // pc only
    @include media-breakpoint-up(sm) {
        padding: 30px;
    }
      // sp only
      @include media-breakpoint-down(xs) {
        padding: 15px;
      }
    }
  }
}

[data-page-id="products-info-detail"] {
  .content-header--bgimg {
    background-position: 70% center;
    background-size: cover;
    position: relative;
    // pc only
    & + .section{
      margin-top: 0;
    }
    .inner-base {
      @include media-breakpoint-up(sm) {
        margin-top: -100px;
        padding: 125px 4.2vw 280px 4.2vw;
      }
      // sp only
      @include media-breakpoint-down(xs) {
        margin-top: -70px;
        padding: 90px 20px 100px 20px;
      }
    }
  }
  
  .headline--type1{
    @include media-breakpoint-up(sm) {
      font-size: 50px;
      margin-top: 160px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: 70px;
    }
  }
  .headline-productlogo {
    position: absolute;
    @include media-breakpoint-up(sm) {
      right: 4.2vw;
      bottom: 4.2vw;
    }
    @include media-breakpoint-down(xs) {
      right: 20px;
      bottom: 20px;
    }
    img {
      @include media-breakpoint-up(sm) {
        width: 72px;
      }
      @include media-breakpoint-down(xs) {
        width: 40px;
      }
    }
  }
  .headline--type8{
    font-family: 'Roboto', sans-serif;
  }
  .headline--type16{
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: 0;
    }
  }
  .box--type1 {
    @include media-breakpoint-up(sm) {
      // margin-top: -107px;
      padding: 80px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      // margin-top: -20px;
      padding: 20px;
    }
    &.point{
      padding: 0;
      .row__3col{
        margin-bottom: 0;
        box-sizing: border-box;
        // pc only
        @include media-breakpoint-up(sm) {
          // width: 33.3%;
          width: 50%;
          // padding: 55px;
          padding: 55px 4%;
        }
        // sp only
        @include media-breakpoint-down(xs) {
          width: 100%;
          padding: 30px 20px;
        }
        &:not(:first-of-type) {
          // pc only
          @include media-breakpoint-up(sm) {
            border-left: 1px solid #e9e9e9;
          }
          // sp only
          @include media-breakpoint-down(xs) {
            border-top: 1px solid #e9e9e9;
          }
        }
      }
      .icon{
        text-align: center;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        // sp only
        @include media-breakpoint-down(xs) {

        }
        img{
          // pc only
          @include media-breakpoint-up(sm) {
            width: 75px;
            padding-right: 20px;
          }
          // sp only
          @include media-breakpoint-down(xs) {
            width: 55px;
            padding-right: 10px;
          }
        }
        figcaption{
          text-align: left;
          // pc only
          @include media-breakpoint-up(sm) {
            margin-bottom: 5px;
            font-size: 24px;
            font-weight: bold;
          }
          @media screen and (max-width: 976px) and (min-width: 767px), print {
            font-size: 20px;
          }
          // sp only
          @include media-breakpoint-down(xs) {
            font-size: 18px;
            padding-left: 5px;
          }
        }
      }
      p{
        color: #555555;
      }
    }
  }
  .section-features{
    background-position: center;
    background-size: cover;
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: 100px;
      padding: 70px 0 20px;
      min-height: 500px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: 20px;
      padding: 50px 0 20px;
    }
    .headline--type16{
      color: #fff;

      @include media-breakpoint-up(sm) {
        margin-bottom: 40px;
      }
      // sp only
      @include media-breakpoint-down(xs) {
        margin-bottom: 25px;
      }
    }
    .row{
      // &:after{
      //   content: "";
      //   display: block;
      //   width: 30%;
      // }
      &._3col {
        &:after{
          @include media-breakpoint-up(sm) {
            content: "";
            width: 30%;
            order: 1;
          }
        }
      }
      &__3col{
        @include media-breakpoint-up(sm) {
          margin-bottom: 100px;
        }
        // sp only
        @include media-breakpoint-down(xs) {
          margin-bottom: 30px;
        }
      }
      &__4col{
        @include media-breakpoint-up(sm) {
          margin-bottom: 80px;
        }
        // sp only
        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-bottom: 30px;
        }
      }
    }
    .icon{
      text-align: center;
      margin: 0;
      padding: 0;
      // sp only
      @include media-breakpoint-down(xs) {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }
      img{
        // pc only
        @include media-breakpoint-up(sm) {
          height: 72px;
        }
        // sp only
        @include media-breakpoint-down(xs) {
          height: 18vw;
          padding-right: 10px;
          width: auto;
        }
      }
      figcaption{
        color: #fff;
        // pc only
        @include media-breakpoint-up(sm) {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-bottom: 5px;
          font-size: 24px;
          font-weight: bold;
          min-height: 3em;
          span{
            font-size: 18px;
          }
        }
        @media screen and (max-width: 976px) and (min-width: 767px), print {
          font-size: 20px;
        }
        // sp only
        @include media-breakpoint-down(xs) {
          font-size: 18px;
          text-align: left;
          padding-left: 5px;
          font-weight: bold;
          span{
            font-size: 15px;
            display: block;
          }
        }
      }
    }
    .icon + .text p,.text li , p , li{
      color: #fff;
      // pc only
      @include media-breakpoint-up(sm) {
        font-size: 16px;
        line-height: 2;
      }
      // sp only
      @include media-breakpoint-down(xs) {
        font-size: 15px;
        line-height: 1.8;
      }
    }
    li{
      position: relative;
      padding-left: 25px;
      // sp only
      @include media-breakpoint-down(xs) {
        padding-left: 20px;
      }
      &:not(:first-of-type){
        margin-top: 8px;
      }
      &:before{
        content: "";
        background-color: $brand-secondary;
        width: 10px;
        height: 2px;
        position: absolute;
        left: 0;
        top: 1em;
      }
    }
  }
  .section-structure{
    background-color: #fff;
    margin-top: 0;
    // pc only
    @include media-breakpoint-up(sm) {
      padding-top: 70px;
      padding-bottom: 120px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .diagram{
      max-width: 903px;
      position: relative;
      // pc only
      @include media-breakpoint-up(sm) {
        margin: 80px auto 0;
      }
      // sp only
      @include media-breakpoint-down(xs) {
        margin: 35px auto 0;
      }
      img{
        width: 100%;
      }
      .diagram__detail{
        position: absolute;
        // pc only
        @include media-breakpoint-up(sm) {
          width: 60px;
          height: 60px;
        }
        // sp only
        @include media-breakpoint-down(xs) {
          width: 40px;
          height: 40px;
        }
        span{
          font-size: 20px;
          color: #fff;
          font-weight: bold;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          z-index: 3;
        }
        &:before{
          content: "";
          background-color: $brand-secondary;
          border-radius: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 2;
          transform: translate(-50%,-50%);
          // pc only
          @include media-breakpoint-up(sm) {
            width: 40px;
            height: 40px;
          }
          // sp only
          @include media-breakpoint-down(xs) {
            width: 30px;
            height: 30px;
          }
        }
        &:after{
          content: "";
          background-color: $brand-secondary;
          border-radius: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          opacity: .6;
          z-index: 1;
          transform: translate(-50%,-50%);
          animation: icon-plus 3s linear infinite normal;
          // pc only
          @include media-breakpoint-up(sm) {
            width: 60px;
            height: 60px;
          }
          // sp only
          @include media-breakpoint-down(xs) {
            width: 40px;
            height: 40px;
          }
        }
        &--01{
          top: 7%;
          left: 22%;
        }
        &--02{
          top: 13%;
          right: 32%;
        }
        &--03{
          top: 60%;
          left: 42%;
        }
        &--04{
          top: 58%;
          right: 2%;
        }
      }
    }
  }
  .section-separator{
    margin-top: 0;
    .separator{
      background-position: center;
      background-size: cover;
      // pc only
      @include media-breakpoint-up(sm) {
        height: 550px;
      }
      // sp only
      @include media-breakpoint-down(xs) {
        height: 200px;
      }
    }
    .inner-base{
      // pc only
      @include media-breakpoint-up(sm) {
        margin-top: 65px;
      }
      // sp only
      @include media-breakpoint-down(xs) {
        margin-top: 40px;
      }
    }
  }
  .section-products{
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: 130px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: 50px;
    }
    .row__3col{
      margin-bottom: 35px;
      // sp only
      @include media-breakpoint-down(xs) {
        width: 47%;
        margin-bottom: 15px;
      }
      .image-wrapper + .bodycopy {
        margin-top: 10px;
        @include media-breakpoint-down(xs) {
          line-height: 1.5;
        }
      }
    }


  }
  .section-relates{
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: 100px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: 40px;
    }

    .inner-base {
      > .row {
        @include media-breakpoint-up(xl) {
          display: flex;
        }
        @include media-breakpoint-down(lg) {
          display: block;
        }
        // @include media-breakpoint-up(md) {
        //   display: block;
        // }
        .row__30 {
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
        .row__67 {
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
      }
    }
  }
  .section-download{
    // pc only
    @include media-breakpoint-up(sm) {
      margin-top: 100px;
      margin-bottom: 120px;
    }
    // sp only
    @include media-breakpoint-down(xs) {
      margin-top: 50px;
      margin-bottom: 60px;
    }
    .row__67{
      & > .row{
        align-items: center;
        img{
          // pc only
          @include media-breakpoint-up(sm) {
            width: 100%;
          }
          // sp only
          @include media-breakpoint-down(xs) {
            width: 70%;
            margin-left: 15%;
          }
        }
      }
    }
    .button--type3{
      margin: 35px 0 0 0;
      max-width: 430px;
      width: 100%;
    }
  }

  .inner-base {
    > .row {
      @include media-breakpoint-up(xl) {
        display: flex;
      }
      @include media-breakpoint-down(lg) {
        display: block;
      }
      // @include media-breakpoint-up(md) {
      //   display: block;
      // }
      &._row__767 {
        @media screen and (max-width: 976px) and (min-width: 767px), print {
          display: flex;
        }
      }
      .row__30 {
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        &.center {
          text-align: center;
        }
        .img-pdf {
          max-width: 266px;
          @include media-breakpoint-down(lg) {
            margin-left: 0;
          }
        }
      }
      .row__67 {
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }
  }

}

@keyframes icon-plus {
  0% {
    opacity: .5;
  }
  50% {
    opacity: 0;
  }
100% {
  opacity: .5;
  }
}
