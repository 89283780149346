// -------------------------------------------
//
// sitemap
//
// -------------------------------------------

[data-page-id="sitemap"] {



/*---------------------------------------------
	section
---------------------------------------------*/
  .section--sitemap {
    .inner-base {
      display: flex;
      @include media-breakpoint-down(xs) {
        display: block;
      }
    }
  }

/*---------------------------------------------
	col
---------------------------------------------*/
.col {
  &-sitemap {
    &__010 {
      width: 30%;
      margin-right: 5%;
      @include media-breakpoint-down(xs) {
        width: auto;
        margin-right: 0;
        margin-bottom: 60px;
      }
      &:last-of-type {
        margin-right: 0;
        @include media-breakpoint-down(xs) {
          margin-bottom: 0;
        }
      }
      .col-inner {
        margin-bottom: 60px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}



/*---------------------------------------------
	list
---------------------------------------------*/
  .list {
    &-sitemap {
      &__010 {
        .item {
          margin-bottom: 20px;
          a {
            color: #333333;
            transition: color .5s;
            &:after {
              content: "";
              background: url(/assets/img/top/img_arrow_bl2.png) no-repeat 0 0;
              width: 19px;
              height: 8px;
              background-size: 100%;
              display: inline-block;
              margin-left: 14px;
              transition: margin .5s;
            }
            &:hover {
              color: #00a0e9;
              transition: color .5s;
              &:after {
                margin-left: 20px;
                transition: margin .5s;
              }
            }
          }
        }
      }
    }
  }



}
