#menu-toggle,
#menu-toggle span,
#menu-toggle:before,
#menu-toggle:after {
  display: block;
  margin: 0 auto;
	transition: transform .4s;
	box-sizing: border-box;
}
#menu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 70px;
  background: $brand-primary;
  text-indent: -9999px;
  overflow: hidden;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
#menu-toggle {
	&:before,
	&:after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 1px;
    background-color: #fff;
	}
}
#menu-toggle:before {
  top: 32px;
  left: 20px;
}
#menu-toggle:after {
  bottom: 32px;
  left: 20px;
}

$time: .75s;
$distance: 2.5px;

#menu-toggle:before {
  animation: menu-bar01 $time forwards;
}
@keyframes menu-bar01 {
  0% {
    transform: translateY($distance) rotate(45deg);
  }
  50% {
    transform: translateY($distance) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
#menu-toggle span {
  transition: transform .25s .25s, opacity .4s;
  opacity: 1;
}
#menu-toggle:after {
  animation: menu-bar02 $time forwards;
}
@keyframes menu-bar02 {
  0% {
    transform: translateY($distance * -1) rotate(-45deg);
  }
  50% {
    transform: translateY($distance * -1) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

#menu-toggle.on:before {
  animation: active-menu-bar01 $time forwards;
}
@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY($distance) rotate(0);
  }
  100% {
    transform: translateY($distance) rotate(45deg);
  }
}
#menu-toggle.on span {
  opacity: 0;
}
#menu-toggle.on:after {
  animation: active-menu-bar03 $time forwards;
}
@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY($distance * -1) rotate(0);
  }
  100% {
    transform: translateY($distance * -1) rotate(-45deg);
  }
}