.mfp-bg {
	z-index: 1000000;
  background: #000;
  opacity: 0.8;
}
.mfp-wrap {
	z-index: 1000100;
}

.mfp-fade.mfp-bg{
	opacity: 0;
	transition: all 0.3s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing{
	opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content{
	opacity: 0;
	transition: all 0.3s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content{
	opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content{
	opacity: 0;
}

.mfp-modal {
	max-width: 100%;
	width: 980px;
	margin: 0 auto;
	padding: 80px 20px 40px 20px;
	position: relative;
	box-sizing: border-box;
	&__txt-ttl {
		font-size: 24px;
		color: #fff;
		font-weight: bold;
		margin-bottom: 15px;
		@include media-breakpoint-down(xs) {
			font-size: 22px;
		}
	}
	&__txt-list {
		color: #fff;
	}
	img {
		max-width: 100%;
	}
	img + figcaption {
		margin-top: 30px;
		@include media-breakpoint-down(xs) {
			margin-top: 20px;
		}
	}
	.mfp-close {
		top: 40px;
		right: 20px;
	}
}

// .mfp-arrow {
// 	width: 40px;
// 	height: 40px;
// 	&:after,&:before {
// 		content: none;
// 	}
// 	&.mfp-arrow-right {
// 		// right: 40px;
// 		// background-position: 0 0;
// 		// background-repeat: no-repeat;
// 		// background-size: cover;
// 		// background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAACmklEQVRYhd2ZS09TQRSAP24sEXWlLMSUiqDoyg2autMS39G1uhViYmL8E5rwCwyYqP+BYIwrdeNKXbEReYQ+7C2CbmypVXuPOe3cUmABt3doi18yuenrzJc5d+7MnHaICA3gAGeBYWAIGASiwAETKg9kgC/AJ+AN8AHwAnelggFaVETGRCQtwUmb30aD9LndL3aLyBMRKTUgtpGSidVtS/C2iCxbENuIxrwTRnCPiIzvgNhGxk1fgQT3ichUE+R8pkyf2xLsFJHJJsr5TJq+txScaIGcz9OtBG+1UM5n3cSpf1AfAj4D3Y08uS3yAzgFLGNWBJ/HbSCnHAQe+S/8EYwBc0CkpWpr/AGOAyl/BO+HkfMWMhQv3kO+frMlGDFOlUniNLi21lg9f1d+cloKgzfFyyzZmizq5KhgPGwkbzErhb6rVckTN8RL5+woisQds2UKRcfRHva+fV65erMpiolRJLNkI9UJx+znQuP0HaFLJWM9eHMpihdGkHQubNgzmuJpW/lQyvNpyfderqa7/7p4KTdMuGkVXLGnV6U8l5J89NKaZDLbaKgVLG1CN/H39fuKoLbV4dFGw5R2RHBdmo9dCzOCJespLi9kJB+7YiO9yorOYtfGLFa8xSzFxAiScnH6o3S9q87qELgqOGNDTpIuv1Qu6eIM9Fbleg+HDTvjmHNrOLl0rjJyOoIW5ZSPeg+eC3vf1dZiu8ucEreyWSjPJmU1MWJbrrZZwJz4242x+i2/1lXmgU4bN44FfgMDWt/xN6xa6HnWJnLKC+O06dA0Y66t5Dtw0lzXHZr0jQctllMe+nIVdtvBfVeUPvzi0asmyr0MUjzyW6RJ6Z4wff1/Bcz61tYl4PrW9CK67b8h9pvPC1b+hgD+AeVe5KFrOHmmAAAAAElFTkSuQmCC);
// 		// opacity: 1;
// 		// transition: all 0.2s linear;
// 		// &:hover {
// 		// 	@media(min-width: 769px){
// 		// 		transition: all 0.2s linear;
// 		// 		opacity: 0.7;

// 		// 	}
// 		// }
// 		// media-breakpoint-down(xs) {
// 		// 	margin-top:-20px;
// 		// 	right: 10px;
// 		// }
// 		right: 40px;
// 		opacity: 1;
// 		background-image: none;
// 		display: inline-block;
// 		width: 40px;
// 		height: 40px;
// 		overflow: hidden;
// 		background-color: #fff;
// 		border-radius: 50%;
// 		box-shadow: 0 0 20px rgba(0,0,0,0.1);
// 		transition: transform .3s ease-out, background-color .2s linear;
// 		box-sizing: border-box;
// 		media-breakpoint-down(xs) {
// 			margin-top:-20px;
// 			right: 10px;
// 		}
// 		&:before {
// 			content: "";
// 			position: absolute;
// 			width: 9px;
// 			height: 9px;
// 			border-top: solid 2px #f3002e;
// 			border-right: solid 2px #f3002e;
// 			@include transform(translate(-50%, -50%) rotate(45deg));
// 			top:50%;
// 			left: 50%;
// 			transition: transform .2s linear, background-color .2s linear, left .2s linear;
// 			border-left:none;
// 			border-bottom:none;
// 			margin: 0;
// 		}
// 		&:hover {
// 			@media(min-width: 769px){
// 				background-color: #f3002e;
// 				&:before {
// 					left: calc( 50% + 2px );
// 					border-top: solid 2px #fff;
// 					border-right: solid 2px #fff;
// 				}
// 			}
// 		}
// 	}
// 	&.mfp-arrow-left {
// 		// left: 40px;
// 		// background-position: 0 0;
// 		// background-repeat: no-repeat;
// 		// background-size: cover;
// 		// background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAwhJREFUWAnNmU1vEkEYx2dJ21j1pDXxBbBvVk9eqqk3BeNr9K6mJ60n+ymMTT+BsR7w4icwGuNJvXjSnnqxBlqFbWlTPNmFVGXH/39hKxC6LLOzwJP8mWF2n2d+O8POG4ZQMCllBG7noSQ0CU1AUegARLMgE/oGLULvoc+GYdhIwzOARaF5KAe1a/ShLx9EryHoEPQU2oGCGmMw1pAWSgS6C21Buo0x7yhDwrkfWtBN1SQe6+hvCxQO+6E3TYKFVfSWdfqCxI0D0KuwSDziss6BlpC46blHkLAvLXgCovZ7YRP4iF/34hguMRwPI78MMe2m/UTlpzGoMxWcEVx7gky34chChsfM0JwWROtxdM9ArX+k9ArffqOKMbSi6bbgIxT0ChwfnyxkEhG0HiGn+UWHSXNTlJIzwk5ng4abdtjwMQVpMTu3Ia1Tt+QvcVYWL97XEfNCHx4zEfRR6e+03KUHws7kRGTkhNj3ck5H2AS791zQSDK3IUou3PBxMfghJYzY0aBh6T8p0A9LQfrCzualNXrT6dbtk9dkeXUtSLhG3yUCFhpL/X63f6z/h4sDbsX06+r3vgIBlRahDtzIjUrLxa7KckZlkd2Sc0cZsJicceD4xv5996llTYo3OIBKXVzXvdErspzOKjJ4uhX4FudVXjcjfkwMfkyJyGi0MsQkMDivcCOn1fIE5ApGyTiUOJBjMeEMNYRcXVOKtYfTMgG/7HHRV/Eu5HhcyGxelBIYrL+v+/L1cdMiXxItU13tNGcNX5eA9Pxx+bw4RUAuGLSMEba5Ka2J27rmYjKxhzGPVnb8SIIbIYuXH+oYF+fJ5i5Y48inofb2p4wQjv1B2HEsWLNOEzKDghfh1KUUNVVlqrQgQ6BzjyD5Ch3i9y5aAXWfAWD9pgkFW7gw20Uwt+pZF84tqEvRkr27cScpAHv76KMKycOj11CnjHX5Ozxy+xsOfdCzDhCyDu6N1AzOvXmAWfs4gOzdI+AG0I4fou+ebtWCtMqjRTkDNfsb4mDVdxspV6+B/4b4B2erBBfqzay6AAAAAElFTkSuQmCC);
// 		// opacity: 1;
// 		// transition: all 0.2s linear;
// 		// &:hover {
// 		// 	@media(min-width: 769px){
// 		// 		transition: all 0.2s linear;
// 		// 		opacity: 0.7;
// 		// 	}
// 		// }
// 		// media-breakpoint-down(xs) {
// 		// 	margin-top:-20px;
// 		// 	left: 10px;
// 		// }
// 		left: 40px;
// 		opacity: 1;
// 		background-image: none;
// 		display: inline-block;
// 		width: 40px;
// 		height: 40px;
// 		overflow: hidden;
// 		background-color: #fff;
// 		border-radius: 50%;
// 		box-shadow: 0 0 20px rgba(0,0,0,0.1);
// 		transition: transform .3s ease-out, background-color .2s linear;
// 		box-sizing: border-box;
// 		media-breakpoint-down(xs) {
// 			margin-top:-20px;
// 			left: 10px;
// 		}
// 		&:before {
// 			content: "";
// 			position: absolute;
// 			width: 9px;
// 			height: 9px;
// 			border-top: solid 2px #f3002e;
// 			border-right: solid 2px #f3002e;
// 			@include transform(translate(-50%, -50%) rotate(-135deg));
// 			top:50%;
// 			left: 50%;
// 			transition: transform .2s linear, background-color .2s linear, left .2s linear;
// 			border-left:none;
// 			border-bottom:none;
// 			margin: 0;
// 		}
// 		&:hover {
// 			@media(min-width: 769px){
// 				background-color: #f3002e;
// 				&:before {
// 					left: calc( 50% - 2px );
// 					border-top: solid 2px #fff;
// 					border-right: solid 2px #fff;
// 				}
// 			}
// 		}
// 	}
// }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor : auto;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	right: 20px;
}
.mfp-close {
	position: absolute;
	color: transparent!important;
	text-indent: -9999px;
	z-index: 2;
	top: -35px;
	display: inline-block;
	width: 35px !important;
	height: 35px !important;
	overflow: hidden;
	border: none;
	background: transparent;
	opacity: 1;

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: #fff;
		border-radius: 5px;
		margin-top: -6px;
	}

	&:active {
		top: 40px;
	}
}


// .mfp-close {
// 	position: absolute;
// 	color: transparent!important;
// 	text-indent: -9999px;
// 	opacity: 1;
// 	&:after {
// 		content: '';
// 		cursor : pointer;
// 		background-position: 0 0;
// 		background-repeat: no-repeat;
// 		background-size: cover;
// 		display: block;
// 		width: 40px;
// 		height: 40px;
// 		background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAADsElEQVRYhcWZ26tVVRSHP7dZejyBVEJe6MEog25eypKO0emhKCgiutAfEHRD6SV6KrCHes2gAn3MUoOOpxNYD/pgQle7QzeoNItKo4fSUslfjPhNmCz32XvNtfc+e8BgM9dca4xvj3kZY801SxINZBawChgHrgYuAhYDozZ1FPgZ+AbYD+wGPgZOFbsKwAJdJGmjpB9ULgckPWUbtX3WjeACYCNwPzDX1yIy+4B3gG8dsb/cNx9YBCwH1gJjwGr3HQc2A08Af/QjgndJ+jWL1w5J44WRD73BzyY5bNsdn+vUOVvSpszglKSVDcCqukLS65ndTfZVBDhP0mRmZEMfwKq6IbM/aZ+1AGdncIf6FLXpdKV9hOySdGYdwGczuAsHCJc0fHxnn893A7wzC/uqGYBLennm9+7pABdkq/XRGYRLut6+j0ha2A4wrdipIcAlfc0ML1QBY3c/5s5BLopuepkZTki6IO5teb9+EJgHvOqcOSz5AngZmAM8kDJJpLvvTd4kQ/Rb15nloKRWXFjtCx8WOhrzfjnS4Z7YfCec5kpsv2emNTHEN3o49xUO62PA7cAUMNKmP6bMTuAO4PFC24llPGi3mfbewn8ZkdvjZ3dXIhmRe8t9eyWNFtq+JxUm0fjEjRUN5lI7yF7hyDbuz6LxixvnN5zsI4ZLkDnc/IY2F9rG4WgcdaOpsSpkr3Chc23nn1aXyVpXoiw/md170td6llZWpo82NBardQK4GXgb2OOdYbrVXUfO9j1/Rjg/dTivbDAUsSDerAxrdU522ie7LZLPI4JfmvaSHiK3F7jFr5vHgNt6jORy/34VgB+5sbbQyNYM7lbDJalCbi+0fa1/99NDqov0tavLah1x+XZToe2U6q6JRit7Eb++h62hXzpmlh+DreXjiK0O6cOFQzEISQwv/c/mf71Y0t89pLx+aSpYj0taqkrJ/5w7J4cIuNMMp5X8oedI+s03rB8C3CP2HS9N57YDxK98GsJQXyrp33ZlX7ub01DHi/uyGYBblp0uvFjtb/dAfvRx0BN3kJE7kM39OXUAyTbYkFMDmpMPZcP6xnQ5u5OBM7yakkz0aV5eYVvKhvW0yNUBTBoL5/fM4Dbv9qVg10l6JbMTB5j3dXuu7hHwecCTPgI+y9fed/33LvA18FNWMMQR8BJXJZH41wFr3HcC2GJ7R7p6LozEEklPZxO7RCK3PpMyRF1t+hkicnh8fojPEFcBFwNLHbmQiOSh7DNElF0fFH+GAP4DtNmkEZfTJmYAAAAASUVORK5CYII=);
// 		position:absolute;
// 		bottom: 10px;
// 		right: 5px;
// 		media-breakpoint-down(xs) {
// 			width: 30px;
// 			height: 30px;
// 		}
// 	}
// 	&:hover {
// 		@media(min-width: 769px){
// 			transition: all 0.2s linear;
// 			opacity: 0.7;
// 		}
// 	}
// }

img.mfp-img {
	padding: 0 20px;
}

.mfp-counter {
	.counter-no {
		font-size: 18px;
		color: #ffffff;
		padding-top: 10px;
		display: inline-block;
		media-breakpoint-down(xs) {
			font-size: 14px;
		}
	}
}

.mfp-figure:after {
	box-shadow: none;
	content: none;
}
