// -------------------------------------------
// 
// フッター
// 
// -------------------------------------------
.common-footer{
	width: 100%;
	background: #00479d;
	a {
		color: #fff;
		&:hover {
			text-decoration: underline;
		}
	}
	.lang-select-pc{
		display: none;
	}
	.lang-select-sp{
		display: none;
	}
	.footer_bottom{
	}
	.bottom-nav{
		display: flex;
	}
	input{
		display: none;
	}
  	// pc only
	// @media (min-width: 977px) {
	@include media-breakpoint-up(xl) {
		display: flex;
		justify-content: center;
		.footer-inner{
			width: calc(100% - 102px);
			padding: 50px 4.2vw;
			box-sizing: border-box;
		}
		.footer-row{
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
			padding-bottom: 20px;
			max-width:  calc(50% + 510px);
			
		}
		.col{
			&:not(:first-of-type){
				padding-left: 30px;
			}
		}
		.logo{
			.en{
				width: 114px;
				display: block;
				margin-bottom: 30px;
			}
			.jp{
				width: 152px;
				display: block;
			}
			img{
				transition: all .3s ease-out;
			}
			a:hover{
				img{
					opacity: .8;
				}
			}
		}
		.main-cat{
			font-size: 15px;
			margin-bottom: 30px;
		}
		.sub-cat{
			margin-bottom: 30px;
			li{
				font-size: 12px;
				margin-bottom: 10px;
				line-height: 1.6;
			}
		}
		label{
			display: none;
		}
		.lang-select-pc{
			display: flex;
			margin-top: 50px;
			li{
				font-size: 14px;
				line-height: 1em;
				&:not(:first-of-type){
					border-left: 1px solid #4d7fbb;
					margin-left: 10px;
					padding-left: 10px;
				}
			}
		}
		.footer_bottom{
			margin: 0 auto;
			justify-content: space-between;
			max-width:  calc(50% + 510px);
			display: flex;
		}
		.bottom-nav{
			font-size: 12px;
			li{
				margin-right: 40px;
			}
		}
		.copy{
			text-align: right;
			font-size: 11px;
			color: #fff;
		}
		.pagetop{
			width: 99px;
			border-left: 0px solid #1a5aa7;
			position: relative;
			overflow: hidden;
			&:before{
				
				content:"";
				display:block;
				width:1px;
				height:250px;
				background-color:#4d7fbb;
				position:absolute;
				top:0;
				left: calc( 50% - 1px);
				display: none;
			}
			&:after{
				display: none;
				content:"";
				display:block;
				width:1px;
				height:0;
				background-color:#fff;
				position:absolute;
				top:250px;
				left: calc( 50% - 1px);
				animation: pagetop-line 2.5s ease-in-out infinite normal;
				display: none;
			}
			a {
				display: none;
				position: absolute;
				display: block;
				width: 70px;
				top: 270px;
				left: 57px;
				font-size: 12px;
				line-height: 1;
				transform: rotate(90deg);
				transform-origin: top left;
				transition: opacity .2s linear;
				display: none;
				&:hover{
					text-decoration: none;
					opacity: .7;
				}
			}
		}
	}
  // sp only
	// @media (max-width: 976px) {
	@include media-breakpoint-down(lg) {
		.logo{
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 25px 0;
			border-bottom: 1px solid #1a5aa7;
			.en{
				width: 114px;
				margin-right: 20px;
			}
			.jp{
				width: 152px;
			}
		}
		.col-inner{
			border-bottom: 1px solid #1a5aa7;
			position: relative;
		}
		.main-cat{
			font-size: 15px;
			
			a{
				padding: 20px;
				display: block;
				width: calc(100% - 100px);
			}
		}
		.sub-cat{
			display: none;
			overflow: hidden;
			li{
				font-size: 12px;
				padding: 10px 20px;
				line-height: 1.6;
				&:last-child{
					margin-bottom: 20px;
				}
				
				a{
					display: block;
				}
			}
		}
		.menu-toggle{
			width: 60px;
			height: 60px;
			position: absolute;
			top: 0;
			right: 0;
		    margin: 0;
		    padding: 0;
	    	text-indent: -9999px;
    		border: 0 none;
    		background-color: transparent;
    		overflow: hidden;
    		outline: none;
			&:after{
				content: "";
				width: 15px;
				height: 1px;
				background: #fff;
				position: absolute;
				top: 30px;
				left: 23px;
			}
			&:before{
				content: "";
				width: 1px;
				height: 15px;
				background: #fff;
				position: absolute;
				top: 23px;
				right: 29px;
				transition: transform .3s ease-out;
			}
			&.on:before{
				transform: rotate(90deg);
			}
		}

		.lang-select-sp{
			display: flex;
			justify-content: center;
			padding: 45px 0 35px;
			li{
				font-size: 14px;
				line-height: 1em;
				&:not(:first-of-type){
					border-left: 1px solid #4d7fbb;
					margin-left: 10px;
					padding-left: 10px;
				}
			}
		}
		.bottom-nav{
			font-size: 12px;
			justify-content: center;
			li{
				margin: 0 2%;
			}
		}
		.copy{
			text-align: center;
			font-size: 11px;
			color: #fff;
			padding: 50px 0;
		}
		.pagetop{
			display: none;
		}
	}
}

.menu-toggle {
  // pc only
	// @media (min-width: 768px) {
	@include media-breakpoint-up(xl) {
		display: none;
	}
  // sp only
	// @media (max-width: 768px) {
	@include media-breakpoint-down(lg) {
		display: block;
	}
}

@keyframes pagetop-line {
    0% {
        height:0px;
		top: 250px;
    }
    50% {
        height:250px;
		top: 0px;
    }
	100% {
        height:0px;
		top: 0px;
    }
}