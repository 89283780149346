// -------------------------------------------
// 
// サブメニュー
// 
// -------------------------------------------
.sub-Menu{
    margin-top: 30px;
    // sp only
    @include media-breakpoint-down(xs) {
        margin-top: 10px;
    }
  .inner-base{
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    // sp only
    @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .button{
        margin: 0 10px;
        background: #fff;
        border: 1px solid #333;
        color: #333;
        font-size: 11px;
        &:visited{
            color: #333 !important;
        }
        &:after{
            position: absolute;
            content: "";
            display: block;
            top: 50%;
            right: 15px;
            width: 10px;
            height: 10px;
            -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
            border: 1px solid #333;
            border-bottom: 0;
            border-left: 0;
        }
        &:hover{
            background: rgb(247, 247, 247);
            &:after{
                right: 10px;
            }
        }
        span{
            padding: 1.3em 26px;
        }
        // sp only
        @include media-breakpoint-down(xs) {
            width: 48%;
            margin: 5px 0;
            font-size: 10px;
            &:after{
                right: 10px;
                width: 7px;
                height: 7px;
            }
            &:hover{
                &:after{
                    right: 5px;
                }
            }
            span{
                padding: 0 15px;
                min-height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &.mattress{
            background: $red;
            border: 1px solid $red;
            color: #fff;
            &:visited{
                color: #fff !important;
            }
            &:after{
                border: 1px solid #fff;
                border-bottom: 0;
                border-left: 0;
            }
            &:hover{
                background: $red-hov;
            }
        }
        &.service{
            background: $blue;
            border: 1px solid $blue;
            color: #fff;
            &:visited{
                color: #fff !important;
            }
            &:after{
                border: 1px solid #fff;
                border-bottom: 0;
                border-left: 0;
            }
            &:hover{
                background: $blue-hov;
            }
        }
    }
  }
}