.image-wrapper {
  position: relative;
  margin: 0;
  overflow: hidden;
  &:before{
    content: "";
    background: rgba(0,0,0,0);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    transition: all .2s linear;
  }
  &--movie{
    &:after{
      content: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%2271px%22%20height%3D%2271px%22%20viewBox%3D%220%200%2071%2071%22%20style%3D%22overflow%3Avisible%3Benable-background%3Anew%200%200%2071%2071%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3Anone%3Bstroke%3A%23FFFFFF%3B%7D%3C%2Fstyle%3E%3Cdefs%3E%3C%2Fdefs%3E%3Ccircle%20id%3D%22%E6%A5%95%E5%86%86%E5%BD%A2_745_1_%22%20class%3D%22st0%22%20cx%3D%2235.5%22%20cy%3D%2235.5%22%20r%3D%2235%22%2F%3E%3Cpath%20id%3D%22__1_%22%20class%3D%22st0%22%20d%3D%22M47.6%2C35.1l-19-11.3v22.6L47.6%2C35.1z%22%2F%3E%3C%2Fsvg%3E');
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      pointer-events: none;
      z-index: 2;
    }
  }
  img {
    width: 100%;
    vertical-align: bottom;
    transition: transform .2s ease-out,opacity .2s linear;
    
  }
  .pc-text-in-img{
    // pc only
    @include media-breakpoint-up(sm) {
      background: url(/assets/img/common/bg_img_shadow.png);
      background-size: cover;
      display: flex;
      align-items: flex-end;
      height: 100%;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 20px;
      box-sizing: border-box;
      color: #fff;
      z-index: 1;
    }
    // sp only
	  @include media-breakpoint-down(xs) {
      background: #fff;
      display: flex;
      span{
        display: flex;
        background: #fff;
        width: 100%;
        align-items: center;
        padding-left: 20px;
        z-index: 5;
      }
    }
  }
  .text-in-img{
    background: url(/assets/img/common/bg_img_shadow.png);
    background-size: cover;
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 20px;
    box-sizing: border-box;
    color: #fff;
    z-index: 2;
    // sp only
	  @include media-breakpoint-down(xs) {
      padding: 10px;
    }
  }
  a:hover{
    &:before{
      background: rgba(0,0,0,0.3);
    }
    img{
      transform:scale(1.05,1.05);
    }
  }
  & + .bodycopy{
    margin-top: 20px;
    // pc only
    @include media-breakpoint-up(sm) {
      line-height: 1.8;
    }
  }
}
a:hover{
  .image-wrapper {
    &:before{
      background: rgba(0,0,0,0.3);
    }
    img{
      transform:scale(1.05,1.05);
    }
  }
}