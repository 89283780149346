.bg {
  // light gray
  &--cl1 {
    background: #f3f5f6;
  }
  &--cl2--sp {
    // sp only
      @include media-breakpoint-down(xs) {
      background: #fff;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
  }
}