// -------------------------------------------
// 
// お問い合わせ
// 
// -------------------------------------------
.common-contact {
    position: relative;
    margin-top: 80px;
    background-color: #212a34;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(/assets/img/common/bg_contact.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0;
        transform: scale(1.5);
        transition: opacity 1s linear, transform 1s cubic-bezier(0,.7,.3,1);
    }
    &.done:before {
        opacity: .3;
        transform: scale(1);
    }
    .button--type1 {
        z-index: 2;
    }
    @include media-breakpoint-up(sm) {
        padding: 0;
        min-height: 250px;
        &:before {
           background-attachment: fixed;
        }
        &:after {
            content: "";
            display: block;
            padding-top: 18%;
        }
        .button--type1 {
            position: absolute;
            width: 430px;
            padding: 20px 0;
            top: calc(50% - 35px);
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    @include media-breakpoint-down(xs) {
        padding: 60px 20px;
        margin-top: 65px;
        &:before {
            background-size: 130%;
        }
    }
}
