// env
//////////////////////////////////////////////////////////
$env: 'development' !default;


// Fonts
//////////////////////////////////////////////////////////
$font-size: 17 !default;
$font-size-s: 12.5 !default;
$line-height: 1.7 !default;

$font-en: "Open Sans", sans-serif !default;
$font-ja: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", YuGothic, "游ゴシック", "メイリオ", Meiryo, sans-serif !default;
$font-family: $font-ja !default;

// Noto sans
// light 		300
// regular 	400
// medium 	500
// bold 		700

// Open sans
// light 		 300
// regular 	 400
// semi-bold 600
// bold 		 700


// Path
//////////////////////////////////////////////////////////

// backgroundの読み込み先を書き出し方で変える時（cnvとか）
// @if $env == 'production' {
//   $path: "http://www.hogehoge.com/assets/";
// }
	
// background: url(#{$path}img/hoge.jpg);


// Spacer
//////////////////////////////////////////////////////////
$spacer:   60px !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;
$side-space: 40px !default;
$side-space-s: 30px !default;



// Width
//////////////////////////////////////////////////////////
$base-width: 1000px !default;
$max-width: 1200px !default;
$is-fix-width: true;// $base-widthの幅で横スクロールを出すかどうか

$header-width: 224px !default;
$header-height-sp: 50px !default;
$main-margin: 80px !default;





// Colors
//////////////////////////////////////////////////////////
$white:  #fff !default;
$black:  #000 !default;
$red:    #da4654 !default;
$red-hov:    #d63048 !default;
$orange: #ed9a16 !default;
$yellow: #ffd500 !default;
$green:  #5cb85c !default;
$blue:   #3b77bc !default;
$blue-hov:   #1e6cba !default;
$teal:   #5bc0de !default;
$pink:   #e4007f !default;
$purple: #613d7c !default;
$brown:  #8c704f !default;

// Create grayscale
$gray-darker:               #333 !default;
$gray-dark:                 #4a4747 !default;
$gray:                      #949494 !default;
$gray-light:                #e6e6e6 !default;
$gray-lighter:              #E6E6EE !default;

// Light Color
$light-green: #ebf4e9 !default;
$light-red:   #f4e9e9 !default;
$light-brown: #c9a77e !default;


// Reassign color vars to semantic color scheme
$brand-primary:             #00479d !default;
$brand-primary-hov:             #2b6ebf !default;
$brand-secondary:           #00a0e9 !default;
$brand-secondary-hov:           #28b2f0 !default;
$brand-icon:              #898989 !default;


$link-color:            $gray-dark !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;



// Breakpoints
//////////////////////////////////////////////////////////
$grid-breakpoints: (
	xs: 0,
	sm: 767px,
	// sm: 737px,
	md: 768px,
	lg: 976px,
	xl: 977px,
) !default;

// sample
// @include media-breakpoint-up(sm) {
// 	// for PC
// }

// @include media-breakpoint-down(xs) {
// 	// for SP
// }




// Border
//////////////////////////////////////////////////////////
$border-width: 1px !default;
$border-color: $gray-dark;




// Horizontal rules
//////////////////////////////////////////////////////////
$hr-border-color: rgba($black,.1) !default;
$hr-border-width: $border-width !default;




// border radius
//////////////////////////////////////////////////////////
$border-radius: 4px !default;




// grid
//////////////////////////////////////////////////////////
$gutter: 24px;
$gutter-s: 14px;

