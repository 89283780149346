// -------------------------------------------
// 
// スクロールバー
// 
// -------------------------------------------
.mCSB_scrollTools {
    width: 10px;
    .mCSB_draggerRail {
        width: 10px;
        height: 100%;
        margin: 0 auto;
        border-radius: 0;
    }
    .mCSB_dragger{
        .mCSB_dragger_bar{
            position: relative;
            width: 10px;
            height: 100%;
            margin: 0 auto;
            border-radius: 0;
            text-align: center;
            background-color: rgba(0,0,0,1);
        }
        &:hover{
            .mCSB_dragger_bar{
                background-color: rgba(0,0,0,.85);
            }
        }
        &_onDrag{
            .mCSB_dragger_bar{
                background-color: rgba(0,0,0,.85)  !important;
            }
        }
    }
}