// -------------------------------------------
// 
// Top
// 
// -------------------------------------------

html {
  height: 100%;
  overflow-y: scroll;
}

img {
  -ms-interpolation-mode: bicubic;
}

[data-page-id="top"] {

/*---------------------------------------------
  Scroll Effect
---------------------------------------------*/

.fade-in .img-bg {
  opacity: 0;
  transform: scale(1.5);
}



/*---------------------------------------------
	Main Visual
---------------------------------------------*/

.hero {
  position: relative;
  overflow: hidden;
  height: 100vh;
  .right-col {
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: 100%;
    background-color: #00479d;
    z-index: 100100;
    @include media-breakpoint-down(lg) {
      width: 60px;
    }
    .t1 {
      position: absolute;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      margin: 0;
      color: #ffffff;
      width: 100px;
      height: 300px;
      display: flex;
      top: calc(42% - 145px);
      font-size: 13px;
      align-items: center;
      @include media-breakpoint-down(lg) {
        width: 60px;
      }
    }
    .t2 {
      position: absolute;
      bottom: 0;
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      margin: 0;
      color: #ffffff;
      width: 100px;
      height: 185px;
      display: flex;
      right: 0;
      font-size: 12px;
      align-items: center;
      @include media-breakpoint-down(lg) {
        width: 60px;
      }
      @include media-breakpoint-down(xs) {
        height: 150px;
      }
      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        height: 100px;
        background-color: #4d7fbb;
        bottom: 0;
        left: calc(50% - 1px);
        @include media-breakpoint-down(xs) {
          height: 70px;
        }
      }
      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 1px;
        height: 0;
        margin-top: 10px;
        background-color: #ffffff;
        bottom: 100px;
        left: calc(50% - 1px);
        animation: scrolldown-line 2.5s ease-in-out infinite normal;
        @keyframes scrolldown-line {
          0% {
            height: 0px;
            bottom: 100px;
          }
          50% {
            height: 100px;
            bottom: 0px;
          }
          100% {
            height: 0px;
            bottom: 0px;
          }
        }
        @include media-breakpoint-down(xs) {
          height: 70px;
          bottom: 70px;
          @keyframes scrolldown-line {
            0% {
              height: 0px;
              bottom: 70px;
            }
            50% {
              height: 70px;
              bottom: 0px;
            }
            100% {
              height: 0px;
              bottom: 0px;
            }
          }
        }
      }
      a {
        color: #fff;
        transition: opacity .2s linear;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  .news-col {
    position: absolute;
    z-index: 100090;
    width: calc(100% - 100px - 7vw);
    height: 100px;
    max-height: 13vh;
    right: 100px;
    bottom: 0;
    background-color: #ffffff;
    overflow: hidden;
    @media screen and (max-width: 1439px) and (min-width: 1240px) {
      left: 50%;
      right: auto;
      width: calc(50% + 510px);
      transform: translateX(-50%);
    }
    @media screen and (max-width: 1239px), print {
      width: calc(100% - 100px - 4.2vw);
    }
    @include media-breakpoint-down(lg) {
      right: 60px;
      width: calc(100% - 60px - 4.2vw);
    }
    @include media-breakpoint-down(xs) {
      width: calc(100% - 80px);
      height: 60px;
    }
    
    .list-home__news {
      width: 1000%;
      height: 100%;
      .item {
        display: flex;
        height: 100%;
        align-items: center;
        a {
          float: left;
          padding: 0 40px;
          transition: color .2s linear;
          @include media-breakpoint-down(xs) {
            padding: 0 20px;
          }
          &:after {
            content: "";
            background: url(/assets/img/top/img_arrow_bl.png) no-repeat 0 0;
            width: 24px;
            height: 8px;
            margin-top: 3px;
            background-size: 100%;
            display: inline-block;
            margin-left: 20px;
            transition: transform .3s ease-out;
            @include media-breakpoint-down(xs) {
              display: none;
            }
          }
          &:hover {
            color: #00a0e9;
            transform: color .2s linear;
            &:after {
              background: url(/assets/img/top/img_arrow_bl2.png) no-repeat 0 0;
              background-size: 100%;
              transform: translate3d(8px, 0, 0);
            }
          }
        }
        .category {
          display: inline-block;
          font-size: 18px;
          color: #00479d;
          margin-right: 40px;
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
        .date {
          display: inline-block;
          font-size: 14px;
          margin-right: 30px;
          @include media-breakpoint-down(xs) {
            margin-right: 16px;
          }
        }
        .title {
          display: inline-block;
          font-size: 15px;
          @include media-breakpoint-down(xs) {
            word-break: keep-all;
          }
        }
      }
    }
  }
}

.mainvisual {
	position: relative;
	width: calc(100% - 100px);
	height: 100%;
	overflow: hidden;
  @include media-breakpoint-down(lg) {
    width: calc(100% - 60px);
  }
  .copy {
    position: absolute;
    width: calc(100% - 7vw);
    right: 0;
    bottom: 0;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: calc(4vh + 100px);
      max-height: 15vh;
    }
    .hero-title {
      color: #ffffff;
      .t1 {
        display: block;
        line-height: 1;
        font-family: Helvetica, Arial, sans-serif;
        font-weight: bold;
        font-size: calc(3vw + 4vh);
        text-transform: uppercase;
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        transition: opacity .8s linear .2s, transform .8s cubic-bezier(0,.7,.3,1) .2s;
        &:nth-of-type(2) {
          transition: opacity .8s linear .35s, transform .8s cubic-bezier(0,.7,.3,1) .35s;
        }
        @include media-breakpoint-down(xs) {
          font-size: 9.7vw;
          padding-bottom: 0.5vh;
        }
      }
      .t2 {
        display: block;
        padding-top: 0.8vh;
        font-size: calc(0.975vw + 1.3vh);
        font-weight: bold;
        letter-spacing: .03em !important;
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        transition: opacity .8s linear .5s, transform .8s cubic-bezier(0,.7,.3,1) .5s;
        @include media-breakpoint-down(xs) {
          font-size: 2.95vw !important;
        }
      }
    }
    @media screen and (max-width: 1439px) and (min-width: 1240px) {
      width: calc(50% + 560px);
      left: calc(50% + 50px);
      right: auto;
      transform: translateX(-50%);
    }
    @media screen and (max-width: 1239px), print {
      width: calc(100% - 4.2vw);
    }

    @include media-breakpoint-down(lg) {
      width: calc(100% - 4.2vw);
      bottom: calc(4vh + 100px);
      &:after {
        content: none;
      }
      .hero-title {
        .t1 {
          font-size: 9vw;
        }
        .t2 {
          font-size: 3.2vw;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      width: calc(100% - 20px);
      bottom: calc(2vh + 60px);
    }
  }
  &.loaded .copy .hero-title .t1,
  &.loaded .copy .hero-title .t2 {
    letter-spacing: -0.03em;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.main-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
  img {
    position: relative;
    display: block;
    width: 100%;
    max-width: none;
    @include media-breakpoint-down(lg) {
      width: auto;
      height: 100%;
    }
  }

}

.loading {
  position: absolute;
  z-index: 100000;
  width: 63px;
  height: 82px;
  top: 0;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  transform-style: preserve-3d;
  perspective: 100px;
  img {
    animation: loading .7s linear 0s infinite;
  }
  @keyframes loading {
    0% {transform: rotateY(0deg);}
    100% {transform: rotateY(360deg);}
  }
}

.mask-container {
	position: absolute;
	width: 100%;
	height: calc(94% - 200px);
	top: calc(2% + 100px);
	left: 0;
}

.mask {
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
  overflow: visible;
  image {
    opacity: 0;
    transform: scale(1.2);
    transition: opacity .8s linear, transform  .8s cubic-bezier(0,.7,.3,1);
    transform-origin: center;
  }
}


@media screen and (max-width: 980px) and (-ms-high-contrast: none){
	.mainvisual .txt img {
		width: 100%;
		max-width: none;
	}
}

@include media-breakpoint-down(xs) {

	.sp-main-bg {
		position: absolute;
		width: 100%;
		height: 80%;
		top: 20%;
		left: 0;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
	}

	.main-bg {
		height: 80%;
	}

	.mask-container {
		height: 80vw;
		top: calc(45% - 40vw);
	}

}


//////////////////////////////////////////////////////////
// section
//////////////////////////////////////////////////////////

@media screen and (min-width: 1440px) {
  .inner-base {
    max-width: calc(100% - 14vw);
  }
  .common-header > .inner-base {
    max-width: none;
  }
}

  .section {
    &-home {
      &__transforming {
        background: #212a34 url(/assets/img/top/bg_transforming_01.png) no-repeat center;
        background-size: cover;
        margin-top: 0;
        @include media-breakpoint-down(xs) {
          background-image: none;
        }
        .inner-base {
          display: flex;
          padding-top: 100px;
          padding-bottom: 100px;
          @include media-breakpoint-down(lg) {
            display: block;
          }
          @include media-breakpoint-down(xs) {
            padding-top: 45px;
            padding-bottom: 45px;
          }
        }
      }
      &__project {
        background-color: #ffffff;
        margin-top: 0;
        .inner-base {
          display: flex;
          padding-top: 110px;
          padding-bottom: 100px;
          @include media-breakpoint-down(lg) {
            display: block;
          }
          @include media-breakpoint-down(xs) {
            display: block;
            padding-top: 45px;
            padding-bottom: 120px;
            position: relative;
          }
          .button__type1 {
            @include media-breakpoint-down(xs) {
              position: absolute;
              bottom: 45px;
              left: 0;
            }
          }
        }
      }
      &__products {
        background-color: #212a34;
        margin-top: 0;
        @include media-breakpoint-down(xs) {
          .row__2col {
            &.title,
            &.details {
              width: auto;
            }
          }
        }
        .inner-base {
          display: flex;
          flex-wrap: wrap;
          padding-top: 90px;
          margin-bottom: 100px;
          @include media-breakpoint-down(xs) {
            display: block;
            padding:0;
            padding-top: 45px;
          }
        }
        .row__1col {
          position: relative;
          margin: 30px 0 -100px;
        }
      }
      &__news {
        background-color: #ffffff;
        margin-top: 0;
        .inner-base {
          display: flex;
          flex-wrap: wrap;
          padding-top: 100px;
          padding-bottom: 12px;
          @include media-breakpoint-down(xs) {
            display: block;
            padding-top: 45px;
            padding-bottom: 120px;
            position: relative;
          }
          .button__type1 {
            @include media-breakpoint-down(xs) {
              position: absolute;
              bottom: 12px;
              left: 0;
            }
          }
        }
      }
    }
  }



//////////////////////////////////////////////////////////
// headline
//////////////////////////////////////////////////////////
.headline {
  &__type1 {
    margin-top: 0;
    margin-bottom: 40px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 30px;
    }
    .t1 {
      color: #00a0e9;
      font-size: 14px;
      display:block;
      margin-bottom: 24px;
      @include media-breakpoint-down(xs) {
      }
    }
    .t1_a {
      color: #00a0e9;
      font-size: 14px;
      display:inline-block;
      margin-bottom: 24px;
      @include media-breakpoint-down(xs) {
        margin-bottom: 16px;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 34px;
        height: 1px;
        background-color: #00a0e9;
        position: relative;
        top: -5px;
        margin: 0 15px;
      }
    }
    .t1_b {
      color: #00a0e9;
      font-size: 14px;
      display:inline-block;
      margin-bottom: 24px;
      @include media-breakpoint-down(xs) {
        margin-bottom: 16px;
      }
    }
    .t1_c {
      color: #00a0e9;
      font-size: 14px;
      display:inline-block;
      margin-bottom: 24px;
      @include media-breakpoint-down(xs) {
        margin-bottom: 16px;
      }
    }
    .t2 {
      color: #000000;
      font-size: 34px;
      display:block;
      line-height: 1.5;
      letter-spacing: 0.05em;
      @include media-breakpoint-down(xs) {
        font-size: 26px;
      }
      &.wht {
        color: #ffffff;
      }
    }
  }
  &__type2 {
    font-size: 22px;
    color: #000000;
    margin: 0 0 20px;
    &.wht {
      color: #ffffff;
    }
    @media screen and (max-width: 1229px) and (min-width: 767px) {
      font-size: 20px;
      margin: 0 0 15px;
    }
  }
  &__type3 {
    font-weight: normal;
    background-color: #00a0e9;
    @include media-breakpoint-down(xs) {
      background-color: #ffffff;
    }
    .t1 {
      color: #ffffff;
      display: inline-block;
      width: 90px;
      display: inline-block;
      font-size: 14px;
      padding: 20px 0;
      text-align: center;
      @include media-breakpoint-down(xs) {
        background-color: #00a0e9;
        padding: 8px 0;
      }
    }
    .t2 {
      background-color: #ffffff;
      display: inline-block;
      font-size: 14px;
      width: 290px;
      padding: 20px 0 20px 25px;
      @include media-breakpoint-down(xs) {
        width: auto;
        padding: 8px 0 8px 8px;
      }
    }
  }
  &__type4 {
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
    margin: 0 0 30px 0;
    letter-spacing: 0.05em;
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
      font-size: 17px;
    }
  }
}

//////////////////////////////////////////////////////////
// text
//////////////////////////////////////////////////////////
.text {
  &__type1 {
    font-size: 19px;
    letter-spacing: 0.05em;
    color: #000000;
    &.wht {
      color: #ffffff;
    }
    @media screen and (max-width: 1229px) and (min-width: 767px) {
      font-size: 16px;
    }
  }
  &__type2 {
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: 0.05em;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
  &__type3 {
    color: #ffffff;
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: 0.05em;
    padding-top: 50px;
    @include media-breakpoint-down(lg) {
      padding: 0 0 20px;
    }
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }
}



//////////////////////////////////////////////////////////
// LIST
//////////////////////////////////////////////////////////
.list {
  &__type1 {
    display: flex;
    @include media-breakpoint-down(xs) {
      display: block;
    }
    .item {
      background-color: #212a34;
      width: 30%;
      min-height: 358px;
      margin-right: 5%;
      position: relative;
      overflow: hidden;
      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-right: 0;
        min-height: 250px;
        margin-bottom: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      .b1 {
        display: block;
        min-height: 358px;
        transform: scale(1.0);
        opacity: 1;
        transition: transform .2s ease-out, opacity .2s linear;
        @include media-breakpoint-down(xs) {
          min-height: 250px;
        }
        &:before {
          content: "";
          display: block;
          padding-top: 147%;
          @include media-breakpoint-down(xs) {
            padding-top: 75%;
          }
        }
        .img-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
      a:hover .b1 {
        transform: scale(1.05);
        opacity: .7;
      } 
      .inner {
        position: absolute;
        min-height: 130px;
        bottom: 0;
        padding: 0 20px 20px 20px;
        z-index: 100;
        @include media-breakpoint-down(xs) {
          min-height: 0;
        }
      }
    }
  }
  &__type2 {
    display: flex;
    border-bottom: 1px solid #dddddd;
    &.sml {
      @include media-breakpoint-down(xs) {
        position: relative;
        margin: 0 20px 10px;
        flex-wrap: nowrap;
        word-break: keep-all;
        border-bottom: 0 none;
      }
    }
    .item {
      text-align: center;
      width: 240px;
      background-color: #ebeeef;
      border: 1px solid #ebeeef;
      border-bottom: 1px solid #dddddd;
      margin-right: 20px;
      position: relative;
      top: 1px;
      @include media-breakpoint-down(xs) {
        width: 47.5%;
        margin-right: 5%;
      }
      &.sml {
        width: 146px;
        @include media-breakpoint-down(xs) {
          width: auto;
          min-width: 125px;
          top: 0;
          margin-right: 20px;
          border-bottom: 0 none;
          &:before {
            content: "";
            position: absolute;
            width: calc(100% + 21px);
            height: 1px;
            bottom: 0;
            right: -21px;
            background-color: #dddddd;
          }
          &.active:before {
            width: 21px;
          }
        }
        a {
          font-size: 15px;
          padding: 14px 0;
          @include media-breakpoint-down(xs) {
            font-size: 14px;
            padding: 12px 20px;
          }
        }
      }
      a {
        color: #999999;
        display: block;
        padding: 20px 0;
        transition: background-color .2s linear;
        @include media-breakpoint-down(xs) {
          font-size: 14px;
          padding: 14px 4px;
        }
      }
      &.active {
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-bottom: 1px solid #ffffff;
        a {
          color: #00479d;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &:not(.active) a:hover {
        background-color: #e4e5e7;
      }
    }
  }
  &__type3 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -14px;
    @include media-breakpoint-down(xs) {
      word-break: keep-all;
      &.mb30 {
        margin: 0 -20px 30px;
        padding: 0 20px;
        overflow-y: scroll;
        flex-wrap: nowrap;
      }
    }
    .link {
      margin-right: 14px;
      margin-bottom: 20px;
      &:last-child {
        margin-right: 0;
      }
      a {
        font-size: 14px;
        color: #00479d;
        background-color: #ebeeef;
        display: block;
        border-radius: 14px;
        padding: 2px 10px;
        white-space: nowrap;
        transition: color .2s , background-color .2s;
        &:hover {
          color: #ebeeef;
          background-color: #00479d;
          transition: color .2s , background-color .2s;
        }
      }
      span {
        font-size: 14px;
        color: #00479d;
        background-color: #ebeeef;
        display: block;
        border-radius: 14px;
        padding: 2px 10px;
        white-space: nowrap;
      }
    }
  }
  &__type4 {
    .item {
      position: relative;
      min-height: 405px;
      overflow: hidden;
      background-color: #212a34;
      margin-bottom: 60px;
      @include media-breakpoint-down(xs) {
        min-height: auto;
        margin-bottom: 20px;
        background-color: #212a34;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .item_img {
        width: 100%;
        height: 100%;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform .2s ease-out, opacity .2s linear;
        @include media-breakpoint-down(xs) {
          position: relative;
          width: 100%;
          height: 0;
          padding-top: 66.6666%;
        }
        .img-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
        }
      }
      a:hover .item_img {
        transform: scale(1.05);
        opacity: .7;
      }
      .item_details {
        position: relative;
        width: 405px;
        min-height: 230px;
        right: 0;
        bottom: 0;
        margin: 175px 0 0 auto;
        background-color: #ffffff;
        @include media-breakpoint-down(xs) {
          position: relative;
          width: 100%;
          min-height: 0;
          margin: 0;
          padding-top: 20px;
        }
        .inner-item {
          padding: 40px 0 10px 30px;
          @include media-breakpoint-down(xs) {
            padding: 20px 0 0 0;
          }
        }
        .list__type3 .link {
          margin-bottom: 10px;
        }
      }
    }
  }
  &__type5 {
    position: relative;
    z-index: 2;
    display: flex;
    overflow: hidden;
    background-color: #212a34;
    @include media-breakpoint-down(xs) {
      display: block;
    }
    .item {
      width: 11.1111%;
      display: block;
      overflow: hidden;
      @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 0;
      }
      a {
        position: relative;
        z-index: 3;
        display: block;
        min-height: 0;
        font-size: 22px;
        color: #ffffff;
        @include media-breakpoint-down(xs) {
          width: auto;
          min-height: auto;
          padding: 40px 20px;
          font-size: 20px;
          position: relative;
        }
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: #162435;
          opacity: 0;
          transition: opacity .2s linear;
          @include media-breakpoint-down(xs) {
            content: none;
          }
        }
        &:after {
          @include media-breakpoint-down(xs) {
            content:"";
            background:url(/assets/img/top/img_arrow.png) no-repeat 0 0;
            width: 24px;
            height: 8px;
            background-size: 100%;
            position: absolute;
            right: 20px;
            top: 45%;
          }
        }
      }
      .t2 {
        position: relative;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 430px;
        opacity: 1;
        transform: scale(1.0);
        transition: opacity .2s linear;
        &:before {
          content: "";
          display: block;
          width: 100%;
          padding-top: 313.8686%;
        }
        @include media-breakpoint-down(xs) {
          position: absolute;
          height: 100%;
          min-height: 0;
          background-position: center !important;
          background-size: cover !important;
          opacity: 1 !important;
          &:before {
            content: none;
          }
        }
      }
      
      &.ct__01 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_01.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_01_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      &.ct__02 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_02.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_02_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      &.ct__03 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_03.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_03_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      &.ct__04 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_04.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_04_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      &.ct__05 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_05.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_05_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      &.ct__06 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_06.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_06_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      &.ct__07 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_07.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_07_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      &.ct__08 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_08.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_08_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      &.ct__09 {
        .t2 {
          background: #ffffff url(/assets/img/top/img_products_09.png) no-repeat 50% 0;
          background-size: cover;
          @include media-breakpoint-down(xs) {
            background: #ffffff url(/assets/img/top/img_products_09_sp.png) no-repeat 50% 0;
            background-size: 110%;
          }
        }
      }
      .t1 {
        position: absolute;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        z-index: 100;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
        @include media-breakpoint-down(xs) {
          position: relative;
          top: 0;
          left: 0;
          transform: none;
          -ms-writing-mode: lr-tb;
          writing-mode: horizontal-tb;;
        }
      }
      .img-bg {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        opacity: 0;
        transition: opacity 0s linear .2s, z-index .0s linear;
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
      &.ct__01 .img-bg {background-image: url(../img/top/bg_products_01.jpg);}
      &.ct__02 .img-bg {background-image: url(../img/top/bg_products_02.jpg);}
      &.ct__03 .img-bg {background-image: url(../img/top/bg_products_03.jpg);}
      &.ct__04 .img-bg {background-image: url(../img/top/bg_products_04.jpg);}
      &.ct__05 .img-bg {background-image: url(../img/top/bg_products_05.jpg);}
      &.ct__06 .img-bg {background-image: url(../img/top/bg_products_06.jpg);}
      &.ct__07 .img-bg {background-image: url(../img/top/bg_products_07.jpg);}
      &.ct__08 .img-bg {background-image: url(../img/top/bg_products_08.jpg);}
      &.ct__09 .img-bg {background-image: url(../img/top/bg_products_09.jpg);}
      &:hover .img-bg {
        z-index: 2;
        opacity: 1;
        transition: opacity .2s linear, z-index 0s linear;
      }
    }
    &:hover .item:hover a:before {
      opacity: .4;
    }
    &:hover .item:not(:hover) a:before {
      opacity: .7;
    }
    &:hover .item .t2 {
      opacity: 0;
    }
  }
  &__type6 {
    li {
      a {
        display: block;
        padding: 30px 0;
        border-bottom: 1px solid #ddd;
        transition: border-color .2s linear;
        &:hover {
          text-decoration: none;
          color: #00a0e9;
          border-color: #00a0e9;
          transition: border-color .2s linear;
        }
        @include media-breakpoint-down(xs) {
          padding: 20px 0;
        }
      }
      .flexbox {
        @include media-breakpoint-down(xs) {
          flex-wrap: wrap;
        }
      }
      .headline__type12 {
        margin-right: 20px;
        font-weight: normal;
        line-height: 1.6;
      }
      .tag--type3 {
        margin-right: 40px;
        @include media-breakpoint-down(xs) {
          margin-right: 0;
        }
      }
      .headline__type13 {
        font-weight: normal;
        line-height: 1.6;
        @include media-breakpoint-down(xs) {
          width: 100%;
          padding-top: 10px;
        }
      }
    }
  }
}


//////////////////////////////////////////////////////////
// col
//////////////////////////////////////////////////////////
.row__2col {
  &.title {
    width: 30%;
    padding-right: 5%;
    @include media-breakpoint-down(lg) {
      width: 100%;
      padding-right: 0;
    }
  }
  &.details {
    width: 65%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    @include media-breakpoint-down(xs) {
      .sp-scroll {
        margin: 0 -20px;
        overflow-y: scroll;
      }
    }
  }
  &.forsp {
    @include media-breakpoint-down(xs) {
      width: auto;
      padding:0 20px;
    }
  }
}

.row__1col {
  width: 100%;
}


//////////////////////////////////////////////////////////
// col
//////////////////////////////////////////////////////////
.button {
  &__type1 {
    width: 100%;
    @include media-breakpoint-down(lg) {
      margin-bottom: 40px;
    }
    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
    a {
      color: #ffffff;
      padding: 16px 20px;
      width: 180px;
      background-color: #00479d;
      display: block;
      position: relative;
      transition: background-color .2s linear;
      @include media-breakpoint-down(xs) {
        width: auto;
        text-align: center;
        padding: 16px 0;
        margin: 0 20px;
      }
      &:hover {
        background-color: #2b6ebf;
      }
      &:after {
        content:"";
        background:url(/assets/img/top/img_arrow.png) no-repeat 0 0;
        width: 24px;
        height: 8px;
        background-size: 100%;
        position: absolute;
        right: 20px;
        top: 45%;
        transition: transform .3s ease-out;
      }
      &:hover:after {
        transform: translate3d(8px, 0, 0);
      }
    }

  }
}

//////////////////////////////////////////////////////////
// col-tabitem
//////////////////////////////////////////////////////////
.col-tabitem {
  display: none;
  &.active {
    display: block;
  }
}


//////////////////////////////////////////////////////////
// Adjust
//////////////////////////////////////////////////////////
.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

}

.view-PC {
  display: block !important;
  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}

.view-SP {
  display: none !important;
  @include media-breakpoint-down(xs) {
    display: block !important;
  }
}

.inline-block {
  display: inline-block !important;
}



