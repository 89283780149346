// -------------------------------------------
// 
// download_list
// 
// -------------------------------------------

[data-page-id="download_list"] {
    .row {
        flex-basis: 22.5%;
        &:before{
            display: block;
            content:"";
            width: 22.5%;
            order:1;
        }
        &:after{
            display: block;
            content:"";
            width: 22.5%;
        }
        &__4col{
            margin: 20px 0;
            display: flex;
            flex-direction: column;
            @include media-breakpoint-up(sm) {
                min-height: 390px;
            }
            @include media-breakpoint-down(xs) {
                margin: 10px 0;
            }
        }
    }
    .image-wrapper{
        margin: -30px -20px 25px -20px;
        @include media-breakpoint-down(xs) {
            margin: -20px -15px 20px -15px;
        }
    }
    .headline--type11{
        @include media-breakpoint-up(sm) {
          }
        @include media-breakpoint-down(xs) {

        }
    }
    .headline--type3{
        color: #555555;
        margin: 10px 0 20px;
    }
    .box--type2{
        @include media-breakpoint-up(sm) {
          }
        @include media-breakpoint-down(xs) {
        }
    }
    .button--type4{
        margin-top: auto;
    }
  
}