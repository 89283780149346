// -------------------------------------------
// 
// 埋め込み動画
// 
// -------------------------------------------
.video {
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  // pc only
  @include media-breakpoint-up(sm) {
    width: 100%;
  }
  // sp only
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}
.hero .video {
  // pc only
  @include media-breakpoint-up(sm) {
    max-width: none;
    iframe {
      // pointer-events: none;
    }
  }
  // sp only
  @include media-breakpoint-down(xs) {
    margin-right: 0;
    margin-left: 0;
  }
}