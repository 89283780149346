// -------------------------------------------
// 
// ページの先頭へ
// 
// -------------------------------------------
.scrollTop {
  position: fixed;
  right: 10px;
  bottom: 30px;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;
  z-index: 100;
}

.scrollTop a {
  font-size: 14px;
  color: #fff;
  background: #06A787;
  padding: 17px 10px 13px;
  border-radius: 100px;
         
}
.scrollTop a:hover{
      background: #52B7A3;
}