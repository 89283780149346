// -------------------------------------------
// 
// タグ
// 
// -------------------------------------------
.tag {
  &--type1{
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    &.left {
      justify-content: left;
    }
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: right;
    }
    a{
        background: #ebeeef;
        margin: 5px;
        padding: 2px 15px;
        border-radius: 20px;
        font-size: 14px;
        color: $brand-primary;
        transition: all .3s ease-out;
        &.is-current{
            background: $brand-primary;
            color: #fff;
            pointer-events: none;
        }
        &:hover{
            background: $brand-primary;
            color: #fff;
        }
    }
  }
  &--type2{
      background: $brand-secondary;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      padding: 5px;
      min-width: 90px;
      box-sizing: border-box;
      font-size: 14px;
      color: #fff;
      // sp only
      @include media-breakpoint-down(xs) {
        min-width: 80px;
        font-size: 13px;
      }
  }
  &--type3{
    background: $brand-secondary;
    display: inline-block;
    padding: 3px;
    min-width: 82px;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    color: #fff;
    // sp only
    @include media-breakpoint-down(xs) {
      min-width: 80px;
    }
}
}