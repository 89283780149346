
.ta-l {text-align: left !important;}
.ta-c {text-align: center !important;}
.ta-r {text-align: right !important;}
.lh-2 {line-height: 2 !important;}

@include media-breakpoint-down(xs) {
	.ta-l-sp {text-align: left !important;}
	.ta-c-sp {text-align: center !important;}
	.ta-r-sp {text-align: right !important;}
}

// .color-primary {color: $blue !important;}
// .color-secondary {color: $orange !important;}
// .color-green {color: $green !important;}
.color-red {color: $red !important;}

@include media-breakpoint-up(sm) {
	@for $i from 10 through 50 {
		.fz-#{$i} {
			font-size: #{$i}px;
		}
	}
}
@include media-breakpoint-down(xs) {
	@for $i from 10 through 50 {
		.fz-#{$i}-sp {
			font-size: #{$i}px;
		}
	}
}