@for $i from 0 through 12 {
	.pt-#{$i * 5} {
		padding-top: $i * 5px !important;
	}
	.pr-#{$i * 5} {
		padding-right: $i * 5px !important;
	}
	.pb-#{$i * 5} {
		padding-bottom: $i * 5px !important;
	}
	.pl-#{$i * 5} {
		padding-left: $i * 5px !important;
	}
}

@include media-breakpoint-down(xs) {
	@for $i from 0 through 12 {
		.pt-#{$i * 5}-sp {
			padding-top: $i * 5px !important;
		}
		.pr-#{$i * 5}-sp {
			padding-right: $i * 5px !important;
		}
		.pb-#{$i * 5}-sp {
			padding-bottom: $i * 5px !important;
		}
		.pl-#{$i * 5}-sp {
			padding-left: $i * 5px !important;
		}
	}
}