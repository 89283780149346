// -------------------------------------------
//
// form
//
// -------------------------------------------

[data-page-id="form"] {
    .section{
        &--form{
            .inner-base{
                max-width: 980px;
            }
        }
    }
    .form-steps{
        margin-bottom: 50px;
        // sp only
        @include media-breakpoint-down(xs) {
            margin-bottom: 40px;
        }
        & > .steps-wraper{
            display: flex;
            .step{
                border: 1px solid #e9e9e9;
                border-right: none;
                position: relative;
                width: 33%;
                height: 58px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: bold;
                // sp only
                @include media-breakpoint-down(xs) {
                    font-size: 12px;
                    height: 35px;
                    padding-left: 10px;
                }
                span{
                    font-weight: normal;
                    padding-left: 12px;
                    // sp only
                    @include media-breakpoint-down(xs) {
                        display: none;
                    }
                }
                &:not(:first-of-type):after,&:not(:first-of-type):before{
                    // position: absolute;
                    // left: -27px;
                    // display: block;
                    // content: "";
                    // background: #fff;
                    // border-right: 1px solid #e9e9e9;
                    // width: 40px;
                    // height: 29px;
                    // // sp only
                    // @include media-breakpoint-down(xs) {
                    //     left: -13px;
                    //     width: 20px;
                    //     height: 18px;
                    // }

                    content: "";
                    width: 0;
                    height: 0;
                    border-left: 30px solid #e9e9e9;
                    border-top: 30px solid transparent;
                    border-bottom: 30px solid transparent;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    @include media-breakpoint-down(xs) {
                        border-left: 18px solid #e9e9e9;
                        border-top: 18px solid transparent;
                        border-bottom: 18px solid transparent;
                    }
                }
                &:not(:first-of-type):after{
                    border-left: 30px solid #fff;
                    left: -2px;
                    @include media-breakpoint-down(xs) {
                        border-left: 18px solid #fff;
                    }
                }
                &:before{
                    // bottom: 0;
                    // transform: skew(-45deg);
                }
                &:after{
                    // top: 0;
                    // transform: skew(45deg);
                }
                &:last-of-type {
                    border: 1px solid #e9e9e9;
                }
                &.is-current{
                    background: $brand-secondary;
                    border: 1px solid $brand-secondary;
                    color: #fff;
                    + :not(:first-of-type):after,+ :not(:first-of-type):before{
                        // background: $brand-secondary;
                        // border-right: 1px solid $brand-secondary;
                        border-left: 30px solid $brand-secondary;
                        @include media-breakpoint-down(xs) {
                            border-left: 18px solid $brand-secondary;
                        }
                    }
                    // + :not(:first-of-type):after {
                    //     border-left: 30px solid $brand-secondary;
                    //     left: -1px;
                    //     @include media-breakpoint-down(xs) {
                    //         border-left: 20px solid $brand-secondary;
                    //     }
                    // }
                }
            }
        }
    }
    .form-wrap{
        margin-bottom: 50px;
        border-top: 1px solid #dddddd;
        @include media-breakpoint-down(xs) {
            margin: 0 -20px 50px;
        }
        .form-item{
            display: flex;
            border-bottom: 1px solid #dddddd;
            @include media-breakpoint-down(xs) {
                display: block;
            }
            &:last-child dt{
                display: block;
            }
        }
        dt{
            background: #f3f5f6;
            width: 30%;
            padding: 20px;
            font-size: 16px;
            line-height: 2;
            font-weight: bold;
            box-sizing: border-box;

            @include media-breakpoint-down(xs) {
                width: 100%;
                border-bottom: 1px solid #dddddd;
            }
            .required{
                background: #cc0000;
                margin-top: 4px;
                padding: 5px 8px;
                float: right;
                font-size: 14px;
                line-height: 1;
                color: #fff;
            }
        }
        dd{
            width: 70%;
            padding: 20px 0 20px 30px;
            font-size: 16px;
            box-sizing: border-box;
            @include media-breakpoint-down(xs) {
                width: 100%;
                padding: 20px;
            }
        }
        &--confirm{
            dd{
                display: flex;
                align-items: center;
                line-height: 1.8;
            }
        }
    }
    .form-item__radio{
        li{
            font-size: 16px;
            &:not(:first-of-type){
                margin-top: 10px;
            }
            label{
                cursor: pointer;
                position: relative;
                padding-left: 23px;
                padding-bottom: 2px;
                @include media-breakpoint-down(xs) {
                    display: block;
                }
            }
            .form-input-radio{
                display: none;
            }
            .form-input-radio[type="radio"]+label:before {
                content: "";
                display: inline-block;
                width: 15px;
                height: 15px;
                border: 1px solid #cccccc;
                background: #fff;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
            .form-input-radio[type="radio"]:checked+label:after{
                content: "";
                display: inline-block;
                width: 9px;
                height: 9px;
                background: $brand-primary;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 4px;
                transform: translateY(-50%);
            }
        }
    }
    .form-item__select{
        position: relative;
        display: inline-block;
        // width: 221px;
        // @include media-breakpoint-down(xs) {
        //     width: 100%;
        // }
        &:after{
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 50%;
            right: 20px;
            border-style: solid;
            border-width: 10px 6px 0 6px;
            border-color: $brand-primary transparent transparent transparent;
            transform: translateY(-50%);
        }
        select{
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            font-family: inherit;
            border-radius: 0;
            box-sizing: border-box;
            color: inherit;

            width: 100%;
            padding: 15px;
            padding-right: 40px;
            font-size: 16px;
            color: #333333;
            border: 1px solid #dddddd;
            appearance: none;
            cursor: pointer;

            &::-ms-expand {
                display: none;
            }
        }
        & + .form-item__input{
            margin-top: 15px;
        }
    }
    .form-item__input{
        display: flex;
        align-items: center;
        input{
            border: 1px solid #dddddd;
            width: 100%;
            padding: 15px;
            font-size: 16px;
            &.input{
                &--w66{
                    width: 66px;
                    margin-right: 10px;
                }
                &--w50p{
                    width: 48%;
                    &:not(:first-of-type){
                        margin-left: 4%;
                    }
                }
            }
        }
    }
    .form-item__textarea{
        textarea{
            border: 1px solid #dddddd;
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            font-size: 16px;
            &.textarea{
                &--row5{
                    height: calc( 1.3em * 5 + 30px);
                    line-height: 1.3;
                    box-sizing: border-box;
                }
            }
        }
    }
    .privacy-wrap{
        height: 220px;
        //padding: 30px;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        .text-area{
            padding: 30px;
            @include media-breakpoint-down(xs) {
                padding: 20px;
            }
        }
    }
    .privacy-agree{
        margin: 40px 0;
        text-align: center;
        @include media-breakpoint-down(xs) {
            margin: 20px 0 40px;
        }
        input{
            display: none;
        }
        label{
            position: relative;
            padding-left: 30px;
            @include media-breakpoint-down(xs) {
                font-size: 14px;
            }
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 3px;
                left: 0;
                width: 18px;
                height: 18px;
                border: 1px solid #cccccc;
                @include media-breakpoint-down(xs) {
                    top: 1px;
                }
              }
        }
        input:checked + label:after{
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 6px;
            width: 5px;
            height: 11px;
            transform: rotate(40deg);
            border-bottom: 3px solid $brand-primary;
            border-right: 3px solid $brand-primary;
        }
    }
    .form-note {
        margin-top: 15px;
        & > li {
            font-size: 13px;
            color: #555555;
            position: relative;
            padding-left: 1em;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "※";
                display: inline-block;
            }
            & + li {
                margin-top: .4em;
            }
        }
    }
    .headline{
        &--form-type1{
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 30px;
            line-height: 2;
            @include media-breakpoint-down(xs) {
                line-height: 2;
            }
        }
        &--form-type2{
            font-size: 20px;
            font-weight: normal;
            margin-top: 70px;
            margin-bottom: 30px;
            line-height: 2;
            text-align: center;
            @include media-breakpoint-down(xs) {
                line-height: 2;
            }
        }
        &--form-privacy{
            font-size: 18px;
            text-align: center;
            color: #1b2930;
            margin-bottom: 15px;
            @include media-breakpoint-down(xs) {
                font-size: 16px;
                margin-bottom: 20px;
            }
        }
    }
    .bodycopy{
        &--form-type1{
            font-size: 16px;
            color: #333333;
            margin-bottom: 30px;
            font-weight: bold;
            @include media-breakpoint-down(xs) {
                margin-bottom: 20px;
            }

        }
        &--form-type2{
            font-size: 16px;
            color: #666666;
            @include media-breakpoint-down(xs) {
            }
        }
        &--form-type3{
            font-size: 16px;
            font-weight: bold;
            margin: 30px 0 15px;
            @include media-breakpoint-down(xs) {
                margin: 20px 0;
            }
        }
    }

    .form-seminar,.form-other{
        display: none;
    }

    .is-error-msg {
        display: none;
        padding: 15px;
        margin: 0 0 30px;
        color: #cc0000;
        font-size: 14px;
        border: solid 1px #cc0000;
    }

    .form-error {
        margin-top: 10px;
        color: #cc0000;
    }
}
