// -------------------------------------------
// 
// パンくず
// 
// -------------------------------------------
.breadcrumb__list {
  margin-top: 100px;
  // pc only
  @include media-breakpoint-up(sm) {
    margin-top: 100px;
    margin-bottom: 50px;
  }
  // sp only
  @include media-breakpoint-down(xs) {
    margin-top: 70px;
    margin-bottom: 20px;
  }
  .breadcrumb__item{
    &.blk {
      a{
        color:#b1b1b1;
        &:hover{
          color: #7f7f7f;
        }
      }
      &:not(:last-child):after {
        border-right: 1px solid #b1b1b1;
        border-top: 1px solid #b1b1b1;
      }
    }
    display: inline;
    font-size: 12px;
    margin-right: 12px;
    color: #7f7f7f;
    &:not(:last-child):after{
      content: " ";
      width: 6px;
      height: 6px;
      border-right: 1px solid rgba(255,255,255,0.5 );
      border-top: 1px solid rgba(255,255,255,0.5);
      display: inline-block;
      transform: rotate(45deg);
      margin-left: 12px;
    }
    a{
      color: rgba(255,255,255,0.7);
      transition: all .2s linear;
      &:hover{
        color: #7f7f7f;
      }
    }
  }
}
.content-header--bgimg{
  .breadcrumb__list{
    .breadcrumb__item{
      color: #fff;
      &:not(:last-child):after{
        border-right: 1px solid rgba(255,255,255,0.5);
        border-top: 1px solid rgba(255,255,255,0.5);
      }
      a{
        color: rgba(255,255,255,0.7);
        &:hover{
          color: rgba(255,255,255,1.0);
        }
      }
    }
  }
}