@function get_vw($size, $viewport:1920){
	$rate: 100 / $viewport;
	@return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10, $viewport:1920 ){
	// font-size: $font_size * 1px;
	// @include media-breakpoint-up(sm) {
	// 	font-size: get_vw($font_size);
	// }
  font-size: get_vw($font_size, $viewport) !important;
}
