// -------------------------------------------
// 
// chart
// 
// -------------------------------------------
.chart{
  dl{
  }
  dt{
    background: #EAEAEA;
    text-align: center;
  }
  dd{
    background: #F7F7F7;
  }
}
.bg--cl1{
  .chart{
    dt{
      background: #F5F5F5;
    }
    dd{
      background: #fff;
    }
  }
}
// pc only
@include media-breakpoint-up(sm) {
  .chart{
    dl{
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
    }
    dt{
      width: 215px;
      box-sizing: border-box;
      padding: 0 20px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    dd{
      width: 785px;
      padding: 40px 60px 20px;
      box-sizing: border-box;
      font-size: 16px;
      p{
        margin-bottom: 20px;
        display: flex;
        .header-line{
            width: 176px;
        }
      }
    }
  }
}
// sp only
@include media-breakpoint-down(xs) {
  dl{
    margin-bottom: 5px;
  }
  dt{
    box-sizing: border-box;
    padding: 15px;
    font-size: 16px;
  }
  dd{
    padding: 15px 15px 5px;
    box-sizing: border-box;
    font-size: 16px;
    p{
      margin-bottom: 20px;
      .header-line{
          display: block;
      }
    }
  }
}