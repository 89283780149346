// -------------------------------------------
//
// リスト関連
//
// -------------------------------------------

.list-dot {
  font-size: 16px;
  line-height: 1.6;
  @include media-breakpoint-down(xs) {
    font-size: 15px;
  }
  & > li {
    position: relative;
    padding-left: 1em;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "・";
      display: inline-block;
    }
    & + li {
      margin-top: .5em;
    }
  }
}
.list-dot-big {
  font-size: 16px;
  line-height: 1.6;
  @include media-breakpoint-down(xs) {
    font-size: 15px;
  }
  & > li {
    position: relative;
    padding-left: 1.5em;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -4px;
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 50%;
    }
    & + li {
      margin-top: .5em;
    }
  }
}
.list-com {
  line-height: 1.6;
  & > li {
    position: relative;
    padding-left: 1em;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "※";
      display: inline-block;
    }
    & + li {
      margin-top: .5em;
    }
  }
}
.list-cir {
  line-height: 1.6;
  & > li {
    position: relative;
    padding-left: 1em;
    &::before {
      position: absolute;
      top: .3em;
      left: 0;
      content: "";
      display: inline-block;
      background: currentColor;
      width: .8em;
      height: .8em;
      border-radius: .4em;
    }
    & + li {
      margin-top: .5em;
    }
  }
	@include media-breakpoint-down(xs) {
    & > li {
      &::before {
        width: 10px;
        height: 10px;
      }
    }
  }
}
.list-num {
  line-height: 1.6;
  counter-reset: number;
  & > li {
    position: relative;
    padding-left: 1.5em;
    &::before {
      counter-increment: number;
      content: counter(number)".";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      text-align: center;
      font-size: 17px;
      font-weight: bold;
      width: 22px;
      height: 20px;
      border-radius: 12px;
      line-height: 1;
      padding-top: 2px;
    }
    & + li {
      margin-top: 1em;
    }
  }
	@include media-breakpoint-down(xs) {
    & > li {
      &::before {
        font-size: 14px;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.list-link {
  & > li {
    border-bottom: 1px solid #a9a9a9;
    &:nth-of-type(1) {
      border-top: 1px solid #a9a9a9;
    }
    & > a,
    & > span {
      display: block;
      position: relative;
      margin: 30px 0;
      line-height: 1;
      color: #333;
      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        content: "";
        display: inline-block;
        border: 1px solid #111;
        border-top: 0;
        border-left: 0;
        width: 8px;
        height: 8px;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
  // pc only
	@include media-breakpoint-up(sm) {
  }
  // sp only
	@include media-breakpoint-down(xs) {
    & > li {
      & > a,
      & > span {
        line-height: 1.5;
        margin: 15px 0;
        padding-right: 15px;
      }
    }
  }
}

.list-dt {

  dt {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 10px;
  }
  dd {
    display: inline-block;
    margin-bottom: 10px;
  }
}

.list-dt2 {
  margin-bottom: 20px;
  dt {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  dd {
    display: block;
  }
}
