// -------------------------------------------
//
// タブ
//
// -------------------------------------------
.tab-block {
  .tab-header{
      .tab-header-inner{
        display: flex;
      }
      .tab{
          background: $brand-primary;
          padding: 20px 0;
          margin-top: 10px;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          border: none;
          cursor: pointer;
          transition: all .2s linear;
          margin-bottom: 0;
          height: 67px;
          box-sizing: border-box;
          // sp only
	        @include media-breakpoint-down(xs) {
            padding: 15px 0;
            font-size: 14px;
            height: 51px;
          }
          &:not(:last-of-type){
            border-right: 1px solid #336cb1;
          }
          &:hover{
            background: $brand-primary-hov;
          }
          &.active{
              background: #f3f5f6;
              padding-top: 30px;
              margin-top: 0;
              color: #000;
              pointer-events: none;
              border-right: none;
              height: 77px;
              box-sizing: border-box;
              // sp only
              @include media-breakpoint-down(xs) {
                padding-top: 25px;
                height: 61px;
              }
          }
          &:focus {
            outline: none;
          }
      }
  }
  &--2{
    .tab-header{
        .tab{
            width: 50%;
            border-right: none !important;
        }
    }
  }
  &--5{
    .tab-header{
      // sp only
      @include media-breakpoint-down(xs) {
        overflow-x: scroll;
      }
      .tab-header-inner{
        // sp only
        @include media-breakpoint-down(xs) {
          width: 765px;
        }
      }
      .tab{
        width: 20%;

      }
    }
  }
  .tab-contents{
      background: #f3f5f6;
      padding: 45px 60px;
  }
  .tab-content-inner{
    display: none;
      &.show{
        display: block;
      }
  }
}


.tag-en_020 {
  .tag--type1 {
    a {
      &:nth-child(1) {
        order: 10;
      }
      &:nth-child(2) {
        order: 20;
      }
      &:nth-child(3) {
        order: 60;
      }
      &:nth-child(4) {
        order: 50;
      }
      &:nth-child(5) {
        order: 70;
      }
      &:nth-child(6) {
        order: 30;
      }
      &:nth-child(7) {
        order: 40;
      }
    }
  }
}